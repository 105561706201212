import { Input, Modal, Popover, Button } from "antd";
import React, { useState, useEffect } from "react";
import { httpUrl, httpPost, httpGet } from "../api/httpClient";
import { stringReducer, setLogin } from "../actions";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock, faGlobe } from "@fortawesome/free-solid-svg-icons";

import Const from "../const";
import SHA256 from "crypto-js/sha256";
import { enc } from "crypto-js";
import logo from "../img/text-logo2.png";

const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const logout = location.state ? location.state.logout : false;
  const language = useSelector((state) => state.stringReducer.language);
  const string = useSelector((state) => state.stringReducer.string);
  const navigate = useNavigate();
  const [user, setUser] = useState({
    id: "",
    password: "",
  });
  const [loginSetting, setLoginSetting] = useState({
    savedId: false,
    autoLogin: false,
  });
  const [globalOpen, setGlobalOpen] = useState(false);
  const [loginFailedVisible, setLoginFailedVisible] = useState(false);

  useEffect(() => {
    const loginSetting = reactLocalStorage.getObject(
      Const.appCode + "#loginSetting"
    );
    if (loginSetting) {
      const userData = reactLocalStorage.getObject(Const.appCode + "#user");
      if (loginSetting.savedId) {
        setUser({ id: userData.id });
        setLoginSetting({ savedId: true, autoLogin: false });
      } else if (loginSetting.autoLogin) {
        setUser({ id: userData.id });
        setLoginSetting({ savedId: false, autoLogin: true });
        if (!logout) {
          autoLogin(userData);
        }
      }
    }
  }, []);

  const autoLogin = async (userData) => {
    let password = userData.password + userData.id;
    let passwordHash = SHA256(password).toString(enc.Hex);
    userData.password = passwordHash;
    try {
      const res = await httpPost(httpUrl.login, [], userData);
      resultFuc(res);
      // if (res.data.result) {
      //     dispatch(setLogin(res.data.user));
      //     reactLocalStorage.setObject(Const.appCode + "#userinfo", res.data.user);
      //     if (loginSetting.savedId || loginSetting.autoLogin) {
      //         reactLocalStorage.setObject(Const.appCode + "#user", user);
      //         reactLocalStorage.setObject(Const.appCode + "#loginSetting", loginSetting);
      //     }
      //     navigate('/Currency');
      // } else { loginError(res.data) };
    } catch (e) {
      Modal.info({
        title: string.login,
        content: string.loginFailedMessage,
        onOk() {},
      });
    }
  };

  const resultFuc = (res) => {
    const result = res.data;
    if (result.result) {
      dispatch(setLogin(result.user));
      reactLocalStorage.setObject(Const.appCode + "#userinfo", result.user);
      if (loginSetting.savedId || loginSetting.autoLogin) {
        reactLocalStorage.setObject(Const.appCode + "#user", user);
        reactLocalStorage.setObject(
          Const.appCode + "#loginSetting",
          loginSetting
        );
      } else {
        reactLocalStorage.remove(Const.appCode + "#user");
        reactLocalStorage.remove(Const.appCode + "#loginSetting");
      }
      navigate("/Currency");
    } else {
      let message = string.loginFailedMessage;
      if (result.reason == "INVALID_LOGIN_ID")
        message = string.loginFailedWrongId;
      else if (result.reason == "INVALID_LOGIN_PASSWORD")
        message = string.loginFailedWrongPassword;
      else if (result.reason == "INVALID_LOGIN_INFO")
        message = string.loginFailedInvalidLoginInfo;
      else if (result.reason == "EXPIRED")
        message = string.loginFailedExpiredId;

      if (result.reason == "EMAIL_CONFIRM_NOT_YET") {
        setLoginFailedVisible(true);
      } else {
        Modal.info({
          title: string.loginFailedTitle,
          content: message,
          onOk() {},
        });
      }
    }
  };

  const handleSubmit = () => {
    let users = { ...user };
    if (users.id === "" || users.id === undefined) {
      alert(string.signUpRuleMsg09);
      return;
    }
    if (user.password === "" || user.password === undefined) {
      alert(string.signUpRuleMsg10);
      return;
    }
    let password = user.password + user.id;
    let passwordHash = SHA256(password)
      // .createHash('sha256')
      // .update(password, 'utf8')
      // .digest()
      .toString(enc.Hex);
    users.id = user.id;
    users.password = passwordHash;

    httpPost(httpUrl.login, [], users)
      .then((result) => {
        console.log("## login result: " + JSON.stringify(result, null, 4));
        resultFuc(result);
        // result = result.data;
        // if (result.result) {
        //     console.log("## login success");
        //     dispatch(setLogin(result.user));
        //     reactLocalStorage.setObject(Const.appCode + "#userinfo", result.user);
        //     if (loginSetting.savedId || loginSetting.autoLogin) {
        //         reactLocalStorage.setObject(Const.appCode + "#user", user);
        //         reactLocalStorage.setObject(Const.appCode + "#loginSetting", loginSetting);
        //     }
        //     navigate('/Currency');
        // } else { loginError(result) }
      })
      .catch((e) => {
        console.log("## signUp error: " + e);
        Modal.info({
          title: string.login,
          content: string.loginFailedMessage,
          onOk() {},
        });
      });
  };

  const onChange = (type) => {
    if (type === "save") {
      setLoginSetting({
        savedId: !loginSetting.savedId,
        autoLogin: false,
      });
    } else {
      setLoginSetting({
        savedId: false,
        autoLogin: !loginSetting.autoLogin,
      });
    }
  };

  const changeLan = (lan) => {
    dispatch(stringReducer(lan));
  };

  const resendEmail = () => {
    httpGet(httpUrl.userResendEmailSignup, [user.id])
      .then((result) => {
        result = result.data;
        if (result == "SUCCESS") {
          alert(string.emailResent);
        } else {
          let message = string.emailResendFailed;
          if (result == "INVALID_EMAIL") message = string.invalidEmail;
          if (result == "ALREADY_VERIFIED")
            message = string.emailAlreadyVerified;
          if (result == "RECENTLY_SENT") message = string.emailRecentlySent;
          alert(message);
        }
      })
      .catch((e) => {
        console.log("## resendEmail error: " + e);
      });
  };

  return (
    <div id="login">
      {/* 헤더 */}
      <div className="header">
        <Popover
          content={
            <div className="pophover">
              <div
                onClick={() => changeLan("ko")}
                className={language == "ko" ? "lan active" : "lan"}
              >
                한국어
              </div>
              <div
                onClick={() => changeLan("en")}
                className={language == "en" ? "lan active" : "lan"}
              >
                English
              </div>
              <div
                onClick={() => changeLan("ja")}
                className={language == "ja" ? "lan active" : "lan"}
              >
                日本語
              </div>
            </div>
          }
          trigger={"click"}
          open={globalOpen}
          onOpenChange={() => setGlobalOpen(!globalOpen)}
        >
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="hoverEffect"
          >
            <FontAwesomeIcon icon={faGlobe} className="globalImg" />
            <p>Select language</p>
          </div>
        </Popover>
      </div>
      <div className="container">
        <img src={logo} className="logoImg" />
        <div className="formBox">
          <FontAwesomeIcon
            icon={faUser}
            style={{ width: 20, marginRight: 16, marginLeft: 16 }}
            className="icon"
          />
          <input
            className="login-input"
            autoComplete="false"
            placeholder={string.idEmail}
            style={{ margin: 0 }}
            value={user.id}
            onChange={(e) => setUser({ ...user, id: e.target.value.trim() })}
          />
        </div>
        <div className="formBox">
          <FontAwesomeIcon
            icon={faLock}
            style={{ width: 20, marginRight: 16, marginLeft: 16 }}
            className="icon"
          />
          <input
            className="login-input"
            autoComplete="false"
            placeholder={string.password}
            type="password"
            style={{ margin: 0 }}
            value={user.password}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
            onKeyDown={(e) => {
              if (e.key == "Enter") handleSubmit();
            }}
          />
        </div>
        <div className="loginOptionBox">
          <div onClick={() => onChange("save")} className="option">
            <input
              type="checkbox"
              checked={loginSetting.savedId}
              onChange={() => onChange("save")}
              style={{ marginRight: 6, width: 15, height: 15 }}
            />
            <p className={loginSetting.savedId && "optionActive"}>
              {string.savedId}
            </p>
          </div>
          <div onClick={() => onChange("auto")} className="option">
            <input
              type="checkbox"
              checked={loginSetting.autoLogin}
              onChange={() => onChange("auto")}
              style={{ marginRight: 6, width: 15, height: 15 }}
            />
            <p className={loginSetting.autoLogin && "optionActive"}>
              {string.autoLogin}
            </p>
          </div>
        </div>
        <button
          className="loginBtn"
          type="primary"
          htmlType="submit"
          onClick={handleSubmit}
        >
          {string.login}
        </button>
        <div className="loginOptionBox">
          <div onClick={() => navigate("/modifyPassword")} className="findPw">
            {string.findPw}
          </div>
          <div className="slash">/</div>
          <div onClick={() => navigate("/signup")} className="signup">
            {string.signUp}
          </div>
        </div>
      </div>

      <Modal
        title={string.loginFailedTitle}
        open={loginFailedVisible}
        footer={[
          <Button key="emailResend" onClick={resendEmail}>
            {string.emailResend}
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={() => setLoginFailedVisible(false)}
          >
            {string.ok}
          </Button>,
        ]}
      >
        <div style={{ paddingBottom: "10px" }}>{string.signUpMsg03}</div>
      </Modal>
    </div>
  );
};

export default Login;
