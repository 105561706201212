import {
  Form,
  Input,
  Checkbox,
  Button,
  Modal,
  Table,
  Divider,
  Image,
} from "antd";
import React, { useEffect, useState } from "react";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Header from "../components/Header";
import { useSelector } from "react-redux";
import Top from "./Top";
import { ArrowLeftOutlined } from "@ant-design/icons";
import InquiryDetaillModal from "../components/dialog/InquiryDetailModal";
import { reactLocalStorage } from "reactjs-localstorage";
import Const from "../const";

const Inquiry = () => {
  const navigate = useNavigate();
  const userinfo = reactLocalStorage.getObject(Const.appCode + "#userinfo");
  const string = useSelector((state) => state.stringReducer.string);
  const language = useSelector((state) => state.stringReducer.language);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });
  const [list, setList] = useState([]);
  const [inquiryModalVisible, setInquiryModalVisible] = useState(false);
  const [showContent, setShowContent] = useState(0);
  const [customPage, setCustompage] = useState([]);

  const handleTableChange = (page) => {
    setPagination({
      ...pagination,
      current: page,
    });
  };

  const getInquiryList = () => {
    let pageNum = pagination.current;
    let pageSize = pagination.pageSize;
    httpGet(httpUrl.inquiryList, [pageNum, pageSize, language], {}).then(
      (res) => {
        const page = { ...pagination };
        page.total = res.data.totalPage;
        setList(res.data.list == null ? [] : res.data.list);
        setPagination(page);
        let numArray = [];
        for (let i = 1; i < res.data.totalPage + 1; i++) {
          numArray.push(i);
        }
        setCustompage(numArray);
      }
    );
  };

  useEffect(() => {
    getInquiryList();
  }, [pagination.current, inquiryModalVisible]);

  // requestBalance = () => {
  //     console.log("##### Request balance")
  //     httpGet(httpUrl.balance, [0], {}).then((result) => {
  //         result = result.data;
  //         console.log(result)
  //         this.setState({ balances: result })
  //     }).catch(e => {
  //         console.log("## requestBalance error: " + e)
  //     });
  // }
  // requestSystemListBlockchain = () => {
  //     console.log("## Loading systemListBlockchain");
  //     httpGet(httpUrl.systemListBlockchain, [this.props.language], {}).then((result) => {
  //         result = result.data;
  //         console.log('## systemListBlockchain loaded');
  //         this.setState({ blockchains: result })
  //     }).catch((e) => {
  //         console.log('## systemListBlockchain load error: ' + e)
  //     });
  // }
  const changeShowContent = (idx) => {
    if (showContent === idx) {
      setShowContent(0);
    } else setShowContent(idx);
  };

  const onClose = () => {
    setInquiryModalVisible(false);
    getInquiryList();
  };

  return (
    <div id="inquiry">
      <Top />
      <Header />
      <div className="container">
        <div className="backIcon" onClick={() => navigate(-1)}>
          <ArrowLeftOutlined /> Back
        </div>
        <div className="inquiryTitle">
          <div className="mainTitle">{string.myInquiry}</div>
          <div className="moreBtn" onClick={() => setInquiryModalVisible(true)}>
            {string.inquire}
          </div>
        </div>
        <div className="notiTableContainer">
          {inquiryModalVisible && (
            <InquiryDetaillModal
              data={userinfo}
              title={string.registInquiry}
              visible={inquiryModalVisible}
              onClose={onClose}
            />
          )}
          {list.map((data) => {
            return (
              <div className="notiTable">
                <div
                  className="notice"
                  onClick={() => {
                    changeShowContent(data.idx);
                  }}
                >
                  {data.answer === null ? (
                    <div className="question">{string.question}</div>
                  ) : (
                    <div className="answer">{string.answer}</div>
                  )}
                  <div className="title"> {data.title} </div>
                  <div className="date">
                    {moment(data.createDate).format("YY-MM-DD")}
                  </div>
                </div>
                {showContent === data.idx && data.answer !== null && (
                  <div className="content">
                    {data.answer.split("\n").map((line, index) => {
                      return (
                        <div key={index}>
                          <p className="admin">{string.admin}</p>
                          <p>{line}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="custompage">
          {customPage.map((num) => {
            return (
              <div
                onClick={() => handleTableChange(num)}
                className={
                  num === pagination.current
                    ? "customPageNum active"
                    : "customPageNum"
                }
              >
                {num}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Inquiry;
