import logo from "./logo.svg";
import "./App.css";
import { useEffect } from "react";
import con from "./const";
import { httpGet, httpUrl } from "./api/httpClient";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./components/Main";
import Login from "./pages/Login";
import MyWallet from "./pages/MyWallet";
import Currency from "./pages/Currency";
import Notice from "./pages/Notice";
// import Exchange from './pages/Exchange';
// import Mining from './pages/Mining';
// import MiningDetail from './pages/MiningDetail';
import Mypage from "./pages/Mypage";
import Inquiry from "./pages/Inquiry";
import MyWalletDialog from "./components/dialog/MyWalletDialog";
import WalletReceive from "./components/dialog/WalletReceive";
import WalletSend from "./components/dialog/WalletSend";
// import SignUpOk from './pages/SignUpOk';
// import WalletSend from './components/dialog/WalletSend';
import SignUp from "./pages/SignUp";
import SignUpOk from "./pages/SignUpOk";
import ModifyPassword from "./pages/ModifyPassword";
// import "./css/main.scss";
// import "./css/main_m.scss";
import "./css/pages.scss";
import Exchange from "./pages/Exchange";

function App() {
  let exchangeRateLoaded = false;
  useEffect(() => {
    requestCurrencyExchangeRate();
  }, []);

  const requestCurrencyExchangeRate = (hide) => {
    if (con.enableForeignExchangeRate) {
      console.log("##### Request currencyExchangeRate");
      return new Promise((resolve, reject) => {
        httpGet(httpUrl.currencyExchangeRate, [], {}, hide)
          .then((result) => {
            console.log("## Request currencyExchangeRate loaded");
            result = result.data;
            global.exchangeRate = [
              1,
              result.find((x) => x.currency == "KRWUSD").rate,
              result.find((x) => x.currency == "KRWCNY").rate,
              result.find((x) => x.currency == "KRWJPY").rate,
            ];
            exchangeRateLoaded = true;
            resolve();
          })
          .catch((e) => {
            console.log("## requestCurrencyExchangeRate error: " + e);
            reject();
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        exchangeRateLoaded = true;
        global.exchangeRate = [1, 1, 1, 1];
        resolve();
      });
    }
  };

  return (
    <div id="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/myWallet" element={<MyWallet />} />
            <Route exact path="/currency" element={<Currency />} />
            <Route exact path="/notice" element={<Notice />} />
            {/* <Route exact path="/exchange" element={<Exchange/>} /> */}
            {/* <Route exact path="/mining" element={<Mining/>} /> */}
            {/* <Route exact path="/miningDetail" element={<MiningDetail/>} /> */}
            <Route exact path="/Mypage" element={<Mypage />} />
            <Route exact path="/inquiry" element={<Inquiry />} />

            <Route exact path="/myWalletDialog" element={<MyWalletDialog />} />
            <Route exact path="/walletReceive" element={<WalletReceive />} />
            <Route exact path="/walletSend" element={<WalletSend />} />

            {/* <Route exact path="/privacy" element={<Privacy/>} /> */}
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/signupok" element={<SignUpOk />} />
            <Route exact path="/modifyPassword" element={<ModifyPassword />} />
            <Route exact path="/Exchange" element={<Exchange />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
