import Axios from "axios";
import util from "util";
import Const from "../const";
import { reactLocalStorage } from "reactjs-localstorage";
let loadingCount = 0;

global.language = "ko";
global.lanList = ["ko", "en", "ja", "zh"];

const serverUrl =
  Const.serverProtocol + "://" + Const.serverIp + ":" + Const.serverPort;

const makeUrl = (url, params) => {
  var result = serverUrl + url;
  if (params === null) return result;
  params.forEach((param) => {
    result = util.format(result, param);
  });
  return result;
};

const httpExec = (method, url, data) => {
  loadingCount++;
  if (loadingCount === 1)
    global.document.getElementById("loadingSpinner").style.display = "block";

  return new Promise((resolve, reject) => {
    Axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        resolve(response.data);
        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none";
      })
      .catch((error) => {
        // console.log(JSON.stringify(error, null, 4));
        if (error.message.includes("401")) {
          alert("로그인이 만료되었습니다. 다시 로그인해주세요");
          reactLocalStorage.remove(Const.appCode + "#userinfo");
          global.location.href = "/";
        }
        reject(error);
        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none";
      });
  });
};

const httpGet = (url, params, data) => {
  return httpExec("GET", makeUrl(url, params), data);
};

const httpPut = (url, params, data) => {
  return httpExec("PUT", makeUrl(url, params), data);
};

const httpPost = (url, params, data) => {
  return httpExec("POST", makeUrl(url, params), data);
};

const httpDownload = (url, params, data) => {
  return new Promise((resolve, reject) => {
    Axios({
      method: "GET",
      url: makeUrl(url, params),
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
    })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        resolve(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const imageUrl = (idx) => {
  return (
    Const.serverProtocol +
    "://" +
    Const.serverIp +
    ":" +
    Const.serverPort +
    Const.serverContext +
    "/file/" +
    idx
  );
};

const httpUrl = {
  idPwdCheck: "/idPwCheck",
  login: "/login",
  logout: "/logout",
  

  //시세 페이지
  currencies: '/coin/tickers',
  noticeList:'/notice/list?pageNum=%s&pageSize=%s&language=%s',
  systemListBlockchain: '/system/list/blockchain?language=%s',
  userCheckEmail: '/user/check/email/%s',
  userPhoneauthGenerate: '/sms/dispatch',
  userPhoneauthCheck: '/sms/verify?token=%s&code=%s',
  signUp: '/user/signup?language=%s',
  userResendEmailSignup: '/user/resend/email/signup/%s',
  userFindPassword :'/user/password/modify/email/%s?language=%s',
  balance: '/wallet/balance/%s',
  walletSend: '/wallet/send',
  walletCheckFee: '/wallet/check/fee?coinType=%s&address=%s',
  walletCheckFee2: '/wallet/check/fee2?coinType=%s&address=%s',
//   exchangeRate: '/currency/exchangeRate',
//   changePassword: '/user/find/password/token',
inquiryCreate:'/inquiry/create',
inquiryList:'/inquiry/list?pageNum=%s&pageSize=%s&language=%s',

  addCoin: '/wallet/add/coin',
  transLog: '/wallet/history/transfer?pageNum=%s&pageSize=%s&coinType=%s',
   walletOtpGetKey: '/wallet/otp/get/key/byEmail',
   walletOtpVerify: '/wallet/otp/verified?key=%s&verifyCode=%s',
   userUpdateNickname: '/user/update/nick/%s',
   changePassword2: '/user/change/password',
   changeSecPassword: '/user/change/security/password',
//   walletOtpChangeKey: '/wallet/otp/change/key/byEmail',
   otpRemove: '/wallet/otp/remove?verifyCode=%s',
  
   FileUpload: "/file/upload",
   kycStatus: '/user/kyc/status',
   kycRegist: '/user/kyc/%s',
//   getFileList:'/file/list2',

   emailAuthGenerate:'/sms/sendCerticodeByEmail',
//   emailVerified:'/sms/codeVerifiedByEmail?email=%s&verifyCode=%s',

  currencyUpbit: '/currency/upbitPrice?markets=%s',
  trade: '/wallet/trade',
  coinFee: '/coin/coin/fee/%s',
  getStakingBalance: '/staking/balance',
};

const imageType = ["image/jpeg", "image/png", "image/bmp"];

export {
  serverUrl,
  httpExec,
  makeUrl,
  httpGet,
  httpUrl,
  httpPut,
  httpPost,
  httpDownload,
  imageType,
  imageUrl,
};
