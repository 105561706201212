import { Modal, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLogout, stringReducer } from "../actions";
import { useNavigate } from "react-router-dom";
import logo from "../img/text-logo1.png";
import global from "../img/common/global.png";
import logoutimg from "../img/common/logout.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faLock,
  faGlobe,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

const Top = (props) => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.stringReducer.language);
  const string = useSelector((state) => state.stringReducer.string);
  const navigate = useNavigate();
  const [globalOpen, setGlobalOpen] = useState(false);
  const logout = () => {
    Modal.confirm({
      title: string.myinfoLogout,
      content: string.myinfoLogoutConfirm,
      okText: string.ok,
      cancelText: string.cancel,
      onOk() {
        dispatch(setLogout());
        navigate("/", { state: { logout: true } });
      },
      onCancel() {},
    });
  };

  const changeLan = (lan) => {
    dispatch(stringReducer(lan));
  };

  return (
    <div id="top">
      <img src={require("../img/text-logo2.png")} style={{ width: 150 }} />
      <div>&nbsp;</div>
      <div className="iconContainer">
        <Popover
          content={
            <div className="pophover">
              <div
                onClick={() => changeLan("ko")}
                className={language == "ko" ? "lan active" : "lan"}
              >
                한국어
              </div>
              <div
                onClick={() => changeLan("en")}
                className={language == "en" ? "lan active" : "lan"}
              >
                English
              </div>
              <div
                onClick={() => changeLan("ja")}
                className={language == "ja" ? "lan active" : "lan"}
              >
                日本語
              </div>
            </div>
          }
          trigger={"click"}
          open={globalOpen}
          onOpenChange={() => setGlobalOpen(!globalOpen)}
        >
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="hoverEffect"
          >
            <FontAwesomeIcon
              icon={faGlobe}
              className="globalImg"
              title="Language"
              color="#256bfd"
            />
          </div>
        </Popover>
        <div
          onClick={logout}
          style={{ display: "flex", alignItems: "center", marginLeft: 20 }}
          className="hoverEffect"
        >
          <FontAwesomeIcon
            icon={faSignOutAlt}
            className="globalImg"
            title="Logout"
            color="#256bfd"
          />
        </div>
      </div>
    </div>
  );
};

export default Top;
