export default {
  serverProtocol: "https",
  serverIp: "back.nodemask.io",

  // serverProtocol: 'http',
  // serverIp: "localhost",

  serverPort: "18001",
  serverContext: "",

  enableForeignExchangeRate: false,

  appCode: "Nodemask",
};
