/*
기준단위
btc : satoshi
eth : gwei
*/

import coinunit from './coinunit';

class Coin {
  static generateWords() {
    // let mnemonic = bip39.generateMnemonic(null, null, bip39.wordlists.korean);
    // let list = mnemonic.split(' ');
    
    return ['가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을', '가을'];
  }
  static getCoinUnit(origin) {
    switch (origin) {
      case Coin.origin.btc: return coinunit.btc;
      case Coin.origin.eth: return coinunit.eth;
      case Coin.origin.xrp: return coinunit.xrp;
      case Coin.origin.bch: return coinunit.bch;
      case Coin.origin.ltc: return coinunit.ltc;
      case Coin.origin.eos: return coinunit.eos;
      case Coin.origin.trx: return coinunit.trx;
      case Coin.origin.vet: return coinunit.vet;
      case Coin.origin.omg: return coinunit.omg;
      case Coin.origin.icx: return coinunit.icx;
      case Coin.origin.bnb: return coinunit.bnb;
      case Coin.origin.zil: return coinunit.zil;
      case Coin.origin.zrx: return coinunit.zrx;
      case Coin.origin.ae: return coinunit.ae;
      case Coin.origin.rep: return coinunit.rep;
      case Coin.origin.gnt: return coinunit.gnt;
      case Coin.origin.snt: return coinunit.snt;
      case Coin.origin.mco: return coinunit.mco;
      case Coin.origin.loom: return coinunit.loom;
      case Coin.origin.knc: return coinunit.knc;
      case Coin.origin.xtz: return coinunit.xtz;
      case Coin.origin.ethos: return coinunit.ethos;
      case Coin.origin.powr: return coinunit.powr;
      case Coin.origin.storm: return coinunit.storm;
      case Coin.origin.pay: return coinunit.pay;
      case Coin.origin.salt: return coinunit.salt;
      case Coin.origin.cvc: return coinunit.cvc;
      case Coin.origin.npxs: return coinunit.npxs;
      case Coin.origin.bat: return coinunit.bat;
      case Coin.origin.ht: return coinunit.ht;
      case Coin.origin.blb: return coinunit.blb;
      case Coin.origin.cs: return coinunit.cs;
      case Coin.origin.gbp: return coinunit.gbp;
      case Coin.origin.crc: return coinunit.crc;
      case Coin.origin.xis: return coinunit.xis;
      case Coin.origin.fk: return coinunit.fk;
      case Coin.origin.mvl: return coinunit.mvl;
      case Coin.origin.md: return coinunit.md;
      case Coin.origin.kc: return coinunit.kc;
      case Coin.origin.sc: return coinunit.sc;
      case Coin.origin.flt: return coinunit.flt;
      case Coin.origin.mtt: return coinunit.mtt;
      case Coin.origin.uutt: return coinunit.uutt;
      default: return coinunit.eth;
    }
  }
}
Coin.origin = {
  btc: 1,//비트코인
  eth: 2,//이더리움
  xrp: 3,//리플
  // bch: 4,//비트코인캐시
  // ltc: 5,//라이트코인
  // eos: 6,//이오스
  // trx: 7,//트론
  // vet: 8,//비체인
  // omg: 9,//오미세고
  // icx: 10,//아이콘
  // bnb: 11,//비앤비
  // zil: 12,//질리카
  // zrx: 13,//제로엑스
  // ae: 14,//애터니티
  // rep: 15,//어거
  // gnt: 16,//골렘
  // snt: 17,//스테이터스네트워크토큰
  // mco: 18,//모나코
  // loom: 19,//룸네트워크
  // knc: 20,//카이버네트워크
  // // xtz: 21,//테조스
  // ethos: 22,//에쏘스
  // powr: 23,//파워렛저
  // storm: 24,//스톰
  // pay: 25,//텐엑스페이토큰
  // salt: 26,//솔트
  // cvc: 27,//시빅
  // npxs: 28,//펀디엑스
  // bat: 29,//베이직어텐션토큰
  // ht: 30,//후오비토큰
  blb: 31,//블랍스
  // cs: 32,//크레딧
  // gbp: 33,//겜블팡
  // crc: 34,//채린
  // xis: 35,//자이러스
  fk: 36,//에프케이코인
  // mvl: 37,//엠블
  // md: 38,//마다
  // kc: 39,//케나프
  // sc: 40,//썬코인
  // tsn: 41,//티에스코인
  flt: 42,
  mtt: 43,
  uutt: 4,
};
Coin.fee = {
  1: [0.0004, 0.0006],
  2: [0.0015, 0.0004, 0.0006],  // [플랫타용 수수료, 보통, 빠름]
  3: [0.00002, 0.00003],
  4: [0.0004, 0.0006],
  5: [0.0004, 0.0006],
  6: [0.0004, 0.0006],
  31: [0.0001, 0.0002],
}
Coin.decimalPlace = 1000000;

Coin.fee[7] = Coin.fee[2];
Coin.fee[8] = Coin.fee[2];
Coin.fee[9] = Coin.fee[2];
Coin.fee[10] = Coin.fee[2];
Coin.fee[11] = Coin.fee[2];
Coin.fee[12] = Coin.fee[2];
Coin.fee[13] = Coin.fee[2];
Coin.fee[14] = Coin.fee[2];
Coin.fee[15] = Coin.fee[2];
Coin.fee[16] = Coin.fee[2];
Coin.fee[17] = Coin.fee[2];
Coin.fee[18] = Coin.fee[2];
Coin.fee[19] = Coin.fee[2];
Coin.fee[20] = Coin.fee[2];
Coin.fee[21] = Coin.fee[2];
Coin.fee[22] = Coin.fee[2];
Coin.fee[23] = Coin.fee[2];
Coin.fee[24] = Coin.fee[2];
Coin.fee[25] = Coin.fee[2];
Coin.fee[26] = Coin.fee[2];
Coin.fee[27] = Coin.fee[2];
Coin.fee[28] = Coin.fee[2];
Coin.fee[29] = Coin.fee[2];
Coin.fee[30] = Coin.fee[2];
Coin.fee[32] = Coin.fee[2];
Coin.fee[33] = Coin.fee[2];
Coin.fee[34] = Coin.fee[2];
Coin.fee[35] = Coin.fee[2];
Coin.fee[36] = Coin.fee[2];
Coin.fee[37] = Coin.fee[2];
Coin.fee[38] = Coin.fee[2];
Coin.fee[39] = Coin.fee[2];
Coin.fee[40] = Coin.fee[2];
Coin.fee[41] = Coin.fee[2];
Coin.fee[42] = Coin.fee[2];
Coin.fee[43] = Coin.fee[2];

Coin.priceExist = {
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
  8: true,
  9: true,
  10: true,
  11: true,
  12: true,
  13: true,
  14: true,
  15: true,
  16: true,
  17: true,
  18: true,
  19: true,
  20: true,
  21: true,
  22: true,
  23: true,
  24: true,
  25: true,
  26: true,
  27: true,
  28: true,
  29: true,
  30: true,
  31: true,
  32: true,
  33: false,
  34: false,
  35: false,
  36: true,
  37: true,
  38: false,
  39: false,
  40: false,
  41: false,
  42: false,
  43: false,
}

// module.exports = Coin;
export default Coin;