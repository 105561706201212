
import bigi from 'bigi';
let coinunit = {
    btc: {
        base: bigi.fromHex('05F5E100'),
        satoshi: bigi.fromHex('05F5E100')
    },
    eth: {
        base: bigi.fromHex('3B9ACA00'),
        wei: bigi.fromHex('0DE0B6B3A7640000'),
        gwei: bigi.fromHex('3B9ACA00')
    },
    xrp: {
        base: bigi.fromHex('0F4240'),
        drop: bigi.fromHex('0F4240')
    },
    bch: {
        base: bigi.fromHex('05F5E100'),
        satoshi: bigi.fromHex('05F5E100')
    },
    ltc: {
        base: bigi.fromHex('05F5E100'),
        satoshi: bigi.fromHex('05F5E100')
    },
    blb: {
        base: bigi.fromHex('3B9ACA00'),
        wei: bigi.fromHex('0DE0B6B3A7640000'),
        gwei: bigi.fromHex('3B9ACA00')
    },
    zil: {//erc20인데 단위가 다름 12
        base: bigi.fromHex('3B9ACA00'),
        wei: bigi.fromHex('E8D4A51000'),
        gwei: bigi.fromHex('3B9ACA00')
    },
    mco: {//erc20인데 단위가 다름 8
        base: bigi.fromHex('05F5E100'),
        wei: bigi.fromHex('05F5E100'),
        gwei: bigi.fromHex('05F5E100')
    },
    ethos: {//erc20인데 단위가 다름 8
        base: bigi.fromHex('05F5E100'),
        wei: bigi.fromHex('05F5E100'),
        gwei: bigi.fromHex('05F5E100')
    },
    powr: {//erc20인데 단위가 다름 6
        base: bigi.fromHex('0F4240'),
        wei: bigi.fromHex('0F4240'),
        gwei: bigi.fromHex('0F4240')
    },
    salt: {//erc20인데 단위가 다름 8
        base: bigi.fromHex('05F5E100'),
        wei: bigi.fromHex('05F5E100'),
        gwei: bigi.fromHex('05F5E100')
    },
    cvc: {//erc20인데 단위가 다름 8
        base: bigi.fromHex('05F5E100'),
        wei: bigi.fromHex('05F5E100'),
        gwei: bigi.fromHex('05F5E100')
    },
    cs: {//erc20인데 단위가 다름 6
        base: bigi.fromHex('0F4240'),
        wei: bigi.fromHex('0F4240'),
        gwei: bigi.fromHex('0F4240')
    },
}
coinunit.eos = coinunit.eth;
coinunit.trx = coinunit.eth;
coinunit.vet = coinunit.eth;
coinunit.omg = coinunit.eth;
coinunit.icx = coinunit.eth;
coinunit.bnb = coinunit.eth;
coinunit.zrx = coinunit.eth;
coinunit.ae = coinunit.eth;
coinunit.rep = coinunit.eth;
coinunit.gnt = coinunit.eth;
coinunit.snt = coinunit.eth;
coinunit.loom = coinunit.eth;
coinunit.knc = coinunit.eth;
coinunit.xtz = coinunit.eth;
coinunit.storm = coinunit.eth;
coinunit.pay = coinunit.eth;
coinunit.npxs = coinunit.eth;
coinunit.bat = coinunit.eth;
coinunit.ht = coinunit.eth;
coinunit.gbp = coinunit.eth;
coinunit.crc = coinunit.eth;
coinunit.xis = coinunit.eth;
coinunit.fk = coinunit.eth;
coinunit.mvl = coinunit.eth;
coinunit.md = coinunit.eth;
coinunit.kc = coinunit.eth;
coinunit.sc = coinunit.eth;
coinunit.flt = coinunit.eth;
coinunit.mtt = coinunit.eth;
coinunit.uutt = coinunit.eth;
export default coinunit;