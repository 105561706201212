import React, { useEffect } from 'react';

import { Spin } from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import { setCoinList, setLogin } from "../actions";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Const from "../const";

const Main = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = reactLocalStorage.getObject(Const.appCode + "#userinfo");
  const coinlist = reactLocalStorage.getObject(Const.appCode + "#coinlist");   

  let pathname = location.pathname.split("/");
  console.log(pathname[1])
  if (pathname[1] !== "signup" && pathname[1] !== "signupok" && pathname[1] !== "password" && pathname[1] !== "privacy" && pathname[1] !== "modifyPassword") {
    if (!userInfo.idx && location.pathname !== "/") {  
      navigate("/");
    }
    if (userInfo.idx && location.pathname === "/") {
      navigate("/Currency");
    }
  }
  if (coinlist instanceof Array) dispatch(setCoinList(coinlist));
  if (userInfo) dispatch(setLogin(userInfo));

    return (
      <>
        <div
          id="loadingSpinner"
          style={{
            display: "none",
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.3)",
            top: 0,
            left: 0,
            textAlign: "center",
            zIndex: 99999
            // pointerEvents: "none"
          }}>
          <Spin style={{ position: "absolute", top: "50%", zIndex: 99999 }} />
        </div>
        <Outlet />
      </>
    );
  }

export default Main;
