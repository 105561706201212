import { Button, Pagination } from "antd";
import React, { useState, useEffect } from "react";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setCoinList,
  setCoinBalance,
  setCoinCurrency,
  setCoinAddress,
} from "../../actions";
import { ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";
import Header from "../../components/Header";
import Coin from "../../lib/util/coin";
import WalletList from "./WalletList";
import con from "../../const";
import Top from "../../pages/Top";
import { useSelector, useDispatch } from "react-redux";
import { formatBalance, getTxUrl } from "../../lib/util/coinUtil";

const MyWalletDialog = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const coinList = useSelector((state) => state.coinListReducer);
  const language = useSelector((state) => state.stringReducer.language);
  const string = useSelector((state) => state.stringReducer.string);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 3,
  });
  const [list, setList] = useState([]);
  const [coinData, setCoinData] = useState({
    image: "",
    name: "",
    symbol: "",
    balance: "",
    decimal: 18,
  });
  const [coinType, setCoinType] = useState(location.state.coinType);
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);
  let exchangeRate = global.exchangeRate || [];
  let currency = global.currency;

  useEffect(() => {
    getTradeList();
  }, [pagination.current]);

  useEffect(() => {
    requestSystemListBlockchain();
    exchangeRate.length === 0 &&
      requestCurrencyExchangeRate().then(() => {
        exchangeRate = global.exchangeRate;
      });
  }, []);

  // const handleTableChange = (pagination) => {
  //     const pager = { ...pagination };
  //     pager.current = pagination.current;
  //     setPagination({
  //         pagination: pager
  //     })
  // };

  const requestBalance = async () => {
    console.log("##### Request balance");
    httpGet(httpUrl.balance, [0], {})
      .then(async (result) => {
        result = result.data;
        console.log(result);
        dispatch(setCoinBalance(result));
        await getCoinList();
        // this.setState({ balances: result })
      })
      .catch((e) => {
        console.log("## requestBalance error: " + e);
      });
  };
  const requestSystemListBlockchain = () => {
    if (coinList.length > 0) {
      requestBalance();
    } else {
      console.log("## Loading systemListBlockchain");
      httpGet(httpUrl.systemListBlockchain, [language], {})
        .then(async (result) => {
          result = result.data;
          console.log("## systemListBlockchain loaded");
          result.forEach((obj) => {
            obj.status = true;
            obj.address = "";
            obj.balance = 0;
            obj.lockBalance = 0;
            obj.balanceLoaded = false;
            obj.price = 0;
            obj.priceLoaded = false;
            obj.value = 0;
            obj.valueLoaded = false;
            obj.purchaseFee = 0;
            obj.sendInFee = 0;
            obj.sendExFee = 0;
            obj.tradeFee = 0;
            obj.feeLoaded = false;
            obj.serverWallet = "";
            obj.serverWalletLoaded = false;
            obj.change = 0.0;
            obj.market = 0.0;
            obj.trends = [];
          });
          dispatch(setCoinList(result));
          await requestCurrency();
          // this.setState({ blockchains: result })
        })
        .catch((e) => {
          console.log("## systemListBlockchain load error: " + e);
        });
    }
  };

  const getCoinList = async () => {
    setCoinData(coinList.find((x) => x.coinType === coinType));
  };

  const getTradeList = () => {
    let pageNum = pagination.current;
    let pageSize = pagination.pageSize;
    let coinType = location.state.coinType;
    httpGet(httpUrl.transLog, [pageNum, pageSize, coinType])
      .then((result) => {
        result = result.data;

        let list = [];
        result.logs.forEach((obj, idx) => {
          const rowData = {};
          // rowData['transTimeString'] = obj.createDateString;
          rowData["transTimeString"] = moment(obj.createDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          rowData["direction"] = obj.direction;
          rowData["type"] = obj.type;
          rowData["coinType"] = obj.coinType;
          rowData["status"] = obj.status;
          let targetAddr =
            (obj.userName ? "[" + obj.userName + "]" : "") + obj.targetAddr; //.substr(0,8)+'...'
          // let targetCnt = (obj.state === 1 ? obj.toCount : obj.fromCount);
          // (targetCnt > 1) && (targetAddr += util.format(string.extraCount, targetCnt - 1));
          rowData["targetAddr"] = targetAddr;
          rowData["amount"] = obj.amount;
          rowData["memo"] = obj.memo;
          rowData["sourceAddr"] = obj.from;
          rowData["fee"] = obj.fee;
          rowData["txId"] = obj.txid;
          rowData["destinationTag"] = obj.destinationTag;

          let stateBorderColor = "";
          let stateTextColor = "";
          let stateText = "";
          let titleText = "";
          titleText = rowData.targetAddr;
          let shortTxid = rowData.txId ? rowData.txId : "";
          if (shortTxid.length > 10)
            shortTxid =
              shortTxid.substr(0, 5) +
              "..." +
              shortTxid.substr(shortTxid.length - 5, 5);

          if (rowData.direction === "OUT") {
            stateBorderColor =
              rowData.status === "SUCCESS" ? "#8688ff" : "#7bf6ff";
            stateTextColor =
              rowData.status === "SUCCESS" ? "#8688ff" : "#7bf6ff";
            stateText =
              rowData.status === "SUCCESS"
                ? string.sent
                : rowData.status === "FAIL"
                ? string.fail
                : string.sending;
            stateText =
              rowData.type === "I2E"
                ? string.externalTrade + " " + stateText
                : stateText;
            stateText =
              rowData.type === "I2A" ? string.adjustAccount : stateText;
          } else {
            stateBorderColor =
              rowData.status === "SUCCESS" ? "#ff7b7b" : "#7bf6ff";
            stateTextColor =
              rowData.status === "SUCCESS" ? "#ff7b7b" : "#7bf6ff";
            stateText =
              rowData.status === "SUCCESS"
                ? string.received
                : rowData.status === "FAIL"
                ? string.fail
                : string.receiving;
            stateText =
              rowData.type === "E2I"
                ? string.externalTrade + " " + stateText
                : stateText;
            stateText = rowData.type === "AIR" ? string.received : stateText;
          }
          rowData.stateBorderColor = stateBorderColor;
          rowData.stateTextColor = stateTextColor;
          rowData.stateText = stateText;
          rowData.titleText = titleText;
          rowData.shortTxid = shortTxid;

          list.push(rowData);
        });

        // console.log("!!!!! latest page, currenctPage:" + this.latestPageNum + ", " + this.currenctPageNum);
        // console.log("!!!!! rowData: " + JSON.stringify(tableData, null, 4));
        setList(list);
        setPagination({
          ...pagination,
          total: result.totalCount,
        });
        // () => this.state.list.push(
        //     { titleText: "0xeeE866E14eC60b0cC6eF150c69C307b5175f240B", stateText: "외부 출금중", amount: 1, transTimeString: "2022-10-11 15:43:21", stateBorderColor: '#a7ceef', stateTextColor: '#4f95d0' },
        //     { titleText: "[김민우김민우]0x324aa1D343Ae5e31c887ab0319Dba3264FfbEaeE", stateText: "외부 출금중", amount: 1, transTimeString: "2022-10-11 15:43:21", stateBorderColor: '#a7ceef', stateTextColor: '#4f95d0' },
        //     { titleText: "0xeeE866E14eC60b0cC6eF150c69C307b5175f240B", stateText: "외부 출금중", amount: 1, transTimeString: "2022-10-11 15:43:21", stateBorderColor: '#a7ceef', stateTextColor: '#4f95d0' },
        //     { titleText: "0xeeE866E14eC60b0cC6eF150c69C307b5175f240B", stateText: "외부 출금중", amount: 1, transTimeString: "2022-10-11 15:43:21", stateBorderColor: '#a7ceef', stateTextColor: '#4f95d0' },
        //     { titleText: "0xeeE866E14eC60b0cC6eF150c69C307b5175f240B", stateText: "외부 출금중", amount: 1, transTimeString: "2022-10-11 15:43:21", stateBorderColor: '#a7ceef', stateTextColor: '#4f95d0' },
        // )
      })
      .catch(() => {});
  };

  // const setOnCoinType = (coinType) => {
  //     setCoinType(coinType);
  //     setWalletDialogOpen(false)
  //     getCoinList();
  //     getTradeList();
  // }
  const closeWalletList = () => {
    setWalletDialogOpen(false);
  };
  // const calTotalValue = () => {
  //     let totalValue = 0;
  //     let allLoaded = true;
  //     coinList.forEach(coin => {
  //         allLoaded &= coin.valueLoaded;
  //         totalValue += coin.value;
  //     });
  //     // if (allLoaded) return totalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //     // else return '-';
  //     // return (totalValue * this.state.exchangeRate[this.state.currency]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  //     return (totalValue * exchangeRate[currency]).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  // }

  const requestCurrency = (hide) => {
    console.log("## Request currency");
    let target;
    let check;
    return new Promise((resolve, reject) => {
      httpGet(httpUrl.currencies, [], {}, hide)
        .then(async (result) => {
          // this.setState({ blockchains: this.state.blockchains},() => console.log("dddddddddddddddddddd " + JSON.stringify(this.state.blockchains)))
          dispatch(setCoinCurrency(result.data));
          resolve();
          await requestBalance();
        })
        .catch((e) => {
          console.log("## requestCurrency error: " + e);
          reject(e);
        });
    });
  };
  const requestCurrencyExchangeRate = (hide) => {
    if (con.enableForeignExchangeRate) {
      console.log("##### Request currencyExchangeRate");
      return new Promise((resolve, reject) => {
        httpGet(httpUrl.currencyExchangeRate, [], {}, hide)
          .then((result) => {
            console.log("## Request currencyExchangeRate loaded");
            result = result.data;
            global.exchangeRate = [
              1,
              result.find((x) => x.currency === "KRWUSD").rate,
              result.find((x) => x.currency === "KRWCNY").rate,
              result.find((x) => x.currency === "KRWJPY").rate,
            ];
            resolve();
          })
          .catch((e) => {
            console.log("## requestCurrencyExchangeRate error: " + e);
            reject();
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        global.exchangeRate = [1, 1, 1, 1];
        resolve();
      });
    }
  };

  const addCoin = async (coinType) => {
    let result = await httpPost(httpUrl.addCoin, [], { coinType: coinType });
    result = result.data;

    dispatch(setCoinAddress(coinType, result.address, result.destinationTag));
  };
  const onClickReceive = async (coinData) => {
    console.log("##### onClickReceive: " + coinData.address);
    if (coinData.address === "" || coinData.address === null) {
      console.log("## Adding coin!!");
      await addCoin(coinData.coinType);
      navigate("/walletReceive", {
        state: { data: coinList.find((x) => x.coinType === coinData.coinType) },
      });
    } else {
      navigate("/walletReceive", { state: { data: coinData } });
    }
  };
  const handlePagesChange = (current) => {
    setPagination({
      ...pagination,
      current: current,
    });
  };

  return (
    <div id="walletDetail">
      <Top />
      <Header />
      <div className="backIcon" onClick={() => navigate(-1)}>
        <ArrowLeftOutlined /> Back
      </div>
      <div className="coinBox">
        <div className="coin">
          {coinData.image && (
            <div className="coinImg">
              <img src={coinData.image} />
            </div>
          )}
          {coinData.balance && (
            <div className="balance">
              <div className="coinbalance">
                {" "}
                {coinData.balance}
                <span>{coinData.symbol}</span>
              </div>
              <div className="coinWon">
                $
                {(coinData.value * exchangeRate[currency])
                  .toFixed(0)
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </div>
            </div>
          )}
        </div>
        <div className="btnBox">
          <div
            onClick={() =>
              navigate("/walletSend", { state: { data: coinData } })
            }
          >
            {" "}
            {string.walletSend}
          </div>
          <div onClick={() => onClickReceive(coinData)}>
            {string.walletReceive}
          </div>
        </div>
      </div>
      {list.map((rowData, index) => {
        // let amount = (rowData.amount / Coin.getCoinUnit(coinType).base).toFixed(6)
        let amount = formatBalance(rowData.amount, coinData.decimal, 4).toFixed(
          4
        );
        return (
          <div className="listTable" key={index}>
            <div className="wrap">
              <div className="title">
                <div
                  className="tag"
                  style={{
                    borderColor: rowData.stateTextColor,
                    color: rowData.stateTextColor,
                  }}
                >
                  {rowData.stateText}
                </div>
                <span
                  className="amount"
                  style={{ color: rowData.stateTextColor }}
                >
                  {amount}
                </span>
              </div>
              {rowData.shortTxid.length > 0 ? (
                <div
                  className="address link"
                  onClick={() => {
                    let url = getTxUrl(coinType, rowData.txId);
                    if (url.length > 0) window.open(url);
                  }}
                >
                  TXID: {rowData.shortTxid}
                </div>
              ) : (
                <div className="address">{rowData.titleText}</div>
              )}
            </div>
            <div className="date">{rowData.transTimeString}</div>
          </div>
        );
      })}
      {list.length === 0 && <div className="noList">거래내역이 없습니다</div>}
      {pagination.total > 0 && (
        <div className="trade-pagination">
          <Pagination
            defaultCurrent={1}
            total={pagination.total}
            onChange={handlePagesChange}
            pageSize={pagination.pageSize}
          />
        </div>
      )}
      {walletDialogOpen && (
        <WalletList
          coinType={coinType}
          setCoinType={setCoinType}
          close={closeWalletList}
        />
      )}
    </div>
  );
};

export default MyWalletDialog;
