import React, { } from "react";
import string from "../../language/StringKo";
import { useSelector } from "react-redux";

const WalletList = (props) => {
  const coinList = useSelector(state => state.coinListReducer);   
  return (
    <>
      <div className="wallet-list-overlay" onClick={props.close}/>
      <div className="wallet-list-wrapper">
        <div style={{borderBottom: "1px solid", padding: 10}}>{string.selectCoin}</div>
        { coinList.map(item => {
          console.log("vvvvvvvvvvvvvv " + item.coinType)
          console.log("11111111111 " + props.coinType)
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems:'center',
                borderBottom: "1px solid",
                padding: 10
              }}
              key={item.coinType}
              onClick={()=> props.setCoinType(item.coinType)}
            >
              <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <img src={item.image} style={{height: 36, width: 36, marginRight: 10}} /> 
                <div>
                  {item.name}
                  <span>{item.symbol}</span>
                </div>
              </div>
              <img
                src={require('../../img/common/check.png').default}
                style={{height: 24, width: 24}}
                className = {props.coinType === item.coinType ? "wallet-list-img-checked" : "wallet-list-img-unchecked"}
              />
            </div>
          )
        })

        }
      </div>
    </>
  )
}

export default WalletList;