import { Input, Checkbox, Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
// import crypto, { } from 'crypto';
import SHA256 from "crypto-js/sha256";
import { enc } from "crypto-js";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import Coin from "../../lib/util/coin";
import Top from "../../pages/Top";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import Const from "../../const";
import checkOn from "../../img/common/checkOn.png";
import checkOff from "../../img/common/checkOff.png";
import { formatBalance, multiplyBalance } from "../../lib/util/coinUtil";

const WalletSend = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const coinList = useSelector((state) => state.coinListReducer);
  const string = useSelector((state) => state.stringReducer.string);
  const userinfo = reactLocalStorage.getObject(Const.appCode + "#userinfo");
  const [sendAmount, setSendAmount] = useState(0);
  const [targetAddress, setTargetAddress] = useState("");
  const [checkConfirm, setCheckConfirm] = useState(false);
  const [destinationTag, setDestinationTag] = useState("");
  const [secPassword, setSecPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [memo, setMemo] = useState("");
  const [selectedCoinType, setSelectedCoinType] = useState(
    location.state.data.coinType
  );
  const [sendConfirmVisible, setSendConfirmVisible] = useState(false);
  const [sendInfo, setSendInfo] = useState({});
  const [sendTarget, setSendTarget] = useState({});

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    setSendAmount(0);
    setTargetAddress("");
    setCheckConfirm(false);
    setDestinationTag("");
    setSecPassword("");

    setOtp("");
    setMemo("");
    setSelectedCoinType(location.state.data.coinType);
    setSendConfirmVisible(false);

    setSendInfo({});
    setSendTarget({});
  };

  const send = () => {
    // if(!this.state.address){
    //     Modal.info({
    //         content: string.pleaseInsertAddress
    //     })
    //     return;
    // }
    // if(!this.state.amount){
    //     Modal.info({
    //         content: string.pleaseInsertAmount
    //     })
    //     return;
    // }
    // if(!this.state.securityPw){
    //     Modal.info({
    //         content: string.pleaseInsertSecurityPw
    //     })
    //     return;
    // }
    // if(!this.state.otp){
    //     Modal.info({
    //         content: string.pleaseInsertOtp
    //     })
    //     return;
    // }
  };

  const onClickSendCoin = async (values) => {
    console.log("## send coin selected cointype");
    console.log(sendAmount);
    let coin = coinList.find((x) => x.coinType === selectedCoinType);

    if (targetAddress.length <= 0) {
      Modal.info({ content: string.sendCoinInputAddress });
      return;
    }

    if (sendAmount === "" || sendAmount <= 0) {
      Modal.info({ content: string.sendCoinInputAmount });
      return;
    }

    if (Math.floor(sendAmount * Coin.decimalPlace) / Coin.decimalPlace <= 0) {
      Modal.info({ content: string.sendCoinInputAmountLow });
      return;
    }

    if (secPassword.length < 4) {
      Modal.info({ content: string.sendCoinFailedNoSecPassword });
      return;
    }

    if (userinfo.securityLevel > 1 && otp.length < 6) {
      Modal.info({ content: string.inputOtpValidation });
      return;
    }

    if (!checkConfirm) {
      Modal.info({ content: string.sendCoinNeedConfirm });
      return;
    }
    // 수수료 계산
    let result = await httpGet(httpUrl.walletCheckFee2, [
      selectedCoinType,
      targetAddress,
    ]);
    console.log(JSON.stringify(result));
    // result = result.data;

    let fee;

    if (result.result !== "SUCCESS") {
      Modal.info({ content: string.feeRequestFailed });
      return;
    }
    fee = formatBalance(result.data.fee, coin.decimal, 4);
    let feeCoinType = result.data.feeCoinType;

    console.log("## fee: " + fee);

    if (coin.balance < fee) {
      Modal.info({
        content: string.sendCoinLowFee + " (" + fee + " " + coin.symbol + ")",
      });
      return;
    }

    // 코인 전송 시작

    console.log("##### Sending coin");
    let sendamount = parseFloat(sendAmount);
    // let sendAmountBase = Math.round(sendamount * Coin.decimalPlace) / Coin.decimalPlace * Coin.getCoinUnit(coin.coinType).base;
    let sendAmountBase = multiplyBalance(sendamount, coin.decimal);
    debugger;
    let secPasswordHash = SHA256(secPassword + userinfo.id).toString(enc.Hex);
    // let secPasswordHash = crypto.createHash('sha256').update(secPassword + userinfo.id, 'utf8').digest().toString('hex');

    let target = {
      amount: sendAmountBase,
      coinType: coin.coinType,
      to: targetAddress,
      destinationTag: destinationTag,
      otpCode: otp,
      securityPassword: secPasswordHash,
      memo: memo,
      fee: fee,
      feeCoinType,
    };

    console.log("## send target: " + JSON.stringify(target));

    confirmSendCoin(coin, target);
  };

  const confirmSendCoin = (coin, target) => {
    setSendConfirmVisible(true);
    setSendInfo({
      coinName: coin.name,
      coinSymbol: coin.symbol,
      address:
        target.to +
        (coin.coinType === 3 ? "(Tag) " + target.destinationTag : ""),
      // amount: Math.round(target.amount / Coin.getCoinUnit(selectedCoinType).base * Coin.decimalPlace) / Coin.decimalPlace,
      amount: formatBalance(target.amount, coin.decimal, 4),
      fee: target.fee,
      feeUnit: coinList.find((x) => x.coinType == target.feeCoinType).symbol,
      destinationTag: target.destinationTag,
    });
    setSendTarget(target);
  };

  const sendCoin = async () => {
    let result = await httpPost(httpUrl.walletSend, [], sendTarget);

    console.log(JSON.stringify(result));
    result = result.data;

    if (result === "SUCCESS" || result === "APPROVAL") {
      console.log("## sent success");
      Modal.info({
        title: string.sendCoinSuccessTitle,
        content: <div>{string.sendCoinSuccess}</div>,
        onOk() {},
      });
      initData();
      return;
    } else {
      let message = string.sendCoinFailed;
      if (result === "INVALID_ADDRESS")
        message = string.sendCoinFailedInvalidAddress;
      else if (result === "INSUFFICIENT_BALANCE")
        message = string.sendCoinFailedInsufficientBalance;
      else if (result === "LOCKED") message = string.sendCoinFailedLocked;
      else if (result === "INVALID_OTP")
        message = string.sendCoinFailedInvalidOtp;
      else if (result === "INVALID_SECURITY_PASSWORD")
        message = string.sendCoinFailedInvalidSecPassword;
      else if (result === "REQUIRED_OTP")
        message = string.sendCoinFailedOtpRequired;
      else if (result === "LIMIT_EXCEED")
        message = string.sendCoinFailedLimitExceed;
      else if (result === "INVALID_DESTINATIONTAG")
        message = string.sendCoinFailedInvalidDesTag;
      else if (result === "REQUIRED_OTP_DAILY")
        message = string.sendCoinFailedOtpRequired;
      else if (result === "CANNOT_SEND_ORGANIZATION")
        message = string.sendCoinFailedOrganization;
      else if (result === "INVALID_SECURITY_LEVEL")
        message = string.sendCoinFailedSecurityLevelRequired;
      else if (result === "KYC_RESTRICTION_DAY")
        message = string.sendCoinFailedKycDay;

      Modal.info({
        title: string.sendCoinFailedTitle,
        content: <div>{message}</div>,
        onOk() {},
      });
    }
  };

  return (
    <div id="send">
      <Top />
      <Header />
      <div className="backIcon" onClick={() => navigate(-1)}>
        <ArrowLeftOutlined /> Back
      </div>
      <div className="formContainer">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div className="mybalance">
            {string.sendCoinCurrentBalace}
            <span className="balance">
              {location.state.data.balance} {location.state.data.symbol}{" "}
            </span>
          </div>
          <div className="myAvailablebalance">
            ( {string.sendCoinAvailableBalace}
            <span className="balance">
              {location.state.data.balance - location.state.data.lockBalance}{" "}
              {location.state.data.symbol}{" "}
            </span>
            )
          </div>
        </div>

        <p>{string.depositAddress}</p>
        <div className="formBox">
          <input
            className="login-input"
            autoComplete="false"
            placeholder={string.insertAddress}
            value={targetAddress}
            onChange={(e) => setTargetAddress(e.target.value)}
          />
        </div>
        <p>{string.insertAmount}</p>
        <div className="formBox">
          <input
            className="login-input"
            autoComplete="false"
            placeholder={string.insertAmount}
            value={sendAmount}
            onChange={(e) => {
              setSendAmount(e.target.value);
            }}
          />
        </div>
        {selectedCoinType === 3 && (
          <div className="formBox">
            <input
              className="login-input"
              autoComplete="false"
              placeholder={string.inputDestinationTag}
              value={destinationTag}
              onChange={(e) => setDestinationTag(e.target.value)}
            />
          </div>
        )}
        <div className="formBox">
          <input
            className="login-input"
            autoComplete="false"
            placeholder={string.insertMemo}
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
          />
        </div>
        <div className="formBox">
          <input
            className="login-input"
            autoComplete="false"
            type="password"
            placeholder={string.securityPw}
            value={secPassword}
            onChange={(e) => setSecPassword(e.target.value)}
          />
        </div>
        {userinfo.securityLevel >= 2 && (
          <div className="formBox">
            <input
              className="login-input"
              autoComplete="false"
              placeholder="OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
        )}
        <div className="sendInfo">
          <div
            className="option"
            style={{
              marginLeft: 5,
              display: "flex",

              alignItems: "center",
            }}
            onClick={() => {
              setCheckConfirm(!checkConfirm);
            }}
          >
            <input
              type="checkbox"
              checked={checkConfirm}
              onChange={() => {
                setCheckConfirm(!checkConfirm);
              }}
              style={{ marginRight: 6, width: 18, height: 18 }}
            />
            {string.sendCoinCautionConfirm}
          </div>

          <div className="content">
            {string.sendCoinCautionConfirmDescriptionEx}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className="loginBtn"
              type="primary"
              htmlType="submit"
              onClick={onClickSendCoin}
            >
              {string.walletSend}
            </button>
          </div>
        </div>
      </div>

      {sendConfirmVisible && (
        <Modal
          className="trade-send-confirm-modal"
          visible={sendConfirmVisible}
          onOk={sendCoin}
          onCancel={() => setSendConfirmVisible(false)}
          cancelText={string.close}
          okText={string.ok}
        >
          <div className="trade-send-confirm-title">
            {string.sendCoinConfirmDlgTtl}
          </div>
          <div>{string.sendCoinConfirmMessage}</div>
          <br />
          <div style={{ display: "inline-block" }}>{string.address}</div>
          <div
            style={{
              display: "inline-block",
              marginLeft: "10px",
              color: "#693cff",
            }}
          >
            {sendInfo.address}
          </div>
          <br />
          <div style={{ display: "inline-block" }}>{string.value}: </div>
          <div style={{ display: "inline-block", marginLeft: "10px" }}>
            {sendInfo.amount}
            {sendInfo.coinSymbol}
          </div>
          <br />
          <div style={{ display: "inline-block" }}>{string.fee}:</div>
          {sendInfo.fee === 0 ? (
            <div style={{ display: "inline-block", marginLeft: "10px" }}>
              {string.exemption}
            </div>
          ) : (
            <div style={{ display: "inline-block", marginLeft: "10px" }}>
              <div style={{ display: "inline-block", marginLeft: "10px" }}>
                {sendInfo.fee}
              </div>
              <div style={{ display: "inline-block", marginLeft: "10px" }}>
                {sendInfo.feeUnit}
              </div>
            </div>
          )}
        </Modal>
      )}
    </div>
    // <div className="wrapper">
    //     <Top />
    //     <div className="container">
    //         <Header />
    //         <div className="container-box" style={{ textAlign: 'center', paddingBottom: '30px' }}>
    //             <div className="main-title" style={{ texAlign: "left" }}>
    //                 <div className="back-btn" onClick={()=>navigate(-1)}>
    //                     <LeftCircleOutlined style={{ fontSize: 24, color:'#fff' }} />
    //                 </div>
    //             </div>
    //             <div className="charge-inner">
    //                 <div className="charge-inner2">
    //                     현재 잔액
    //                     <span style={{ color: "$mainColor", marginRight: 5, marginLeft: 16, fontWeight:'bold' }}>{location.state.data.balance}</span>
    //                     {location.state.data.symbol}
    //                 </div>
    //                 <p>{string.depositAddress}</p>
    //                 <Input
    //                     style={{ borderRadius: 20, padding: 15, marginBottom: 18, marginTop:18 }}
    //                     placeholder={string.insertAddress}
    //                     onChange={(e) => setTargetAddress(e.target.value)}
    //                     value={targetAddress}
    //                 />
    //                 <p>{string.insertAmount}</p>
    //                 <Input
    //                     style={{ borderRadius: 20, padding: 15, marginBottom: 18, marginTop:18 }}
    //                     placeholder={string.insertAmount}
    //                     onChange={(e) => {setSendAmount(e.target.value) }}
    //                     value={sendAmount}
    //                 />
    //                 {selectedCoinType === 3 && (
    //                 <Input
    //                     style={{ borderRadius: 20, padding: 15, marginBottom: 18, marginTop:18 }}
    //                     placeholder={string.inputDestinationTag}
    //                     onChange={(e) => setDestinationTag(e.target.value)}
    //                     value={destinationTag}
    //                 />
    //                 )}
    //                 <Input
    //                     style={{ borderRadius: 20, padding: 15, marginBottom: 18 }}
    //                     placeholder={string.insertMemo}
    //                     onChange={(e) => setMemo(e.target.value)}
    //                     value={memo}
    //                 />
    //                 <Input.Password
    //                     style={{ borderRadius: 20, padding: 15}}
    //                     placeholder={string.securityPw}
    //                     onChange={(e) => setSecPassword(e.target.value)}
    //                     value={secPassword}
    //                 />
    //                 {userinfo.securityLevel >= 2 && (
    //                     <Input
    //                         style={{borderRadius: 20, padding: 15, marginTop: 18 }}
    //                         placeholder='OTP'
    //                         onChange={(e) => setOtp(e.target.value)}
    //                         value={otp}
    //                     />
    //                 )}
    //                 <div className="contentBlock">
    //                     <div
    //                         className="content-box"
    //                         style={{
    //                             display: "flex",
    //                             flexDirection: "row",
    //                             alignItems:'center',
    //                             marginTop: 24
    //                         }}
    //                     >
    //                         <div style={{color:'#fff', marginRight: 16}}>{string.sendCoinCautionConfirm}</div>
    //                         <Checkbox
    //                             leftTextStyle={{
    //                                 fontSize: 15.7,
    //                                 letterSpacing: 0.05,
    //                                 color: '#000',
    //                                 lineHeight: 20,
    //                             }}
    //                             onClick={() => {setCheckConfirm(!checkConfirm)}}
    //                             isChecked={checkConfirm}
    //                             checkBoxColor="#e1e1e1"
    //                         />
    //                     </div>
    //                     <div className="content-box"
    //                     style={{ fontWeight: "normal", fontSize: 16, textAlign: "left", color:'#fff', wordBreak:'keep-all' }}>
    //                         {string.sendCoinCautionConfirmDescriptionEx.join(" ")}
    //                     </div>
    //                     <Button className="signup-btn-ok" onClick={onClickSendCoin} style={{border:'none', color:"#fff"}}>
    //                         {string.walletSend}
    //                     </Button>
    //                 </div>
    //             </div>
    //         </div>

    //     </div>

    // </div>
  );
};

export default WalletSend;
