export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_COIN_CURRENCY = 'SET_COIN_CURRENCY';
export const SET_COIN_BALANCE = 'SET_COIN_BALANCE'
export const SET_COIN_ADDRESS = 'SET_COIN_ADDRESS'
const SET_COIN_FEE = 'SET_COIN_FEE'
export const SET_COIN_LIST = 'SET_COIN_LIST'
export const SET_LOGIN_INFO_SECURITY_LEVEL = 'SET_LOGIN_INFO_SECURITY_LEVEL'

export function stringReducer(lan) {
  console.log("lan")
  console.log(lan)
  return {
    type: SET_LANGUAGE,
    language: lan
  };
}
export function setLogin(userinfo) {
  return {
    type: SET_LOGIN,
    userinfo: userinfo
  };
}
export function setLogout() {
  return {
    type: SET_LOGOUT,
  };
}
export const setCoinCurrency = coinList => (
  {
    type: SET_COIN_CURRENCY,
    coinList: coinList,
  }
)
export const setCoinList = coinList => (
  {
    type: SET_COIN_LIST,
    coinList: coinList,
  }
)

export const setCoinBalance = coinList => (
  {
    type: SET_COIN_BALANCE,
    coinList: coinList,
  }
);
export const setCoinAddress = (coinType, address, destinationTag) => (
  {
    type: SET_COIN_ADDRESS,
    coinType: coinType,
    address: address,
    destinationTag: destinationTag
  }
);

export const setCoinFee = coinList => (
  {
    type: SET_COIN_FEE,
    coinList: coinList,
  }
);

export const setLoginInfoSecurityLevel = securityLevel => (
  {
    type: SET_LOGIN_INFO_SECURITY_LEVEL,
    securityLevel: securityLevel,
  }
)
