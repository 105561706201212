import React, { useEffect, useState } from "react";
import { httpGet, httpUrl } from "../api/httpClient";
import { useLocation, useNavigate } from "react-router-dom";
import con from "../const";
import { useSelector, useDispatch } from "react-redux";
import string from "../language/StringKo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWallet,
  faExchangeAlt,
  faCoins,
  faBell,
  faQuestionCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const string = useSelector((state) => state.stringReducer.string);
  const location = useLocation();
  const navigate = useNavigate();
  const [currName, setCurrName] = useState("Currency");

  useEffect(() => {
    requestCurrencyExchangeRate();
  }, []);

  const requestCurrencyExchangeRate = (hide) => {
    if (con.enableForeignExchangeRate) {
      console.log("##### Request currencyExchangeRate");
      return new Promise((resolve, reject) => {
        httpGet(httpUrl.currencyExchangeRate, [], {}, hide)
          .then((result) => {
            console.log("## Request currencyExchangeRate loaded");
            result = result.data;
            global.exchangeRate = [
              1,
              result.find((x) => x.currency === "KRWUSD").rate,
              result.find((x) => x.currency === "KRWCNY").rate,
              result.find((x) => x.currency === "KRWJPY").rate,
            ];
            resolve();
          })
          .catch((e) => {
            console.log("## requestCurrencyExchangeRate error: " + e);
            reject();
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        global.exchangeRate = [1, 1, 1, 1];
        resolve();
      });
    }
  };

  const menus = [
    {
      idx: 1,
      name: string.currency,
      url: "/Currency",
      icon: faCoins,
    },
    {
      idx: 2,
      name: string.myWallet,
      url: "/MyWallet",
      icon: faWallet,
    },
    {
      idx: 3,
      name: string.exchange,
      url: "/Exchange",
      icon: faExchangeAlt,
    },
    {
      idx: 4,
      name: string.mining,
      url: "/Mining",
      icon: faCoins,
    },
    {
      idx: 5,
      name: string.notice,
      url: "/notice",
      parent: "/Currency",
      icon: faBell,
    },
    {
      idx: 6,
      name: string.myWalletDialog,
      url: "/myWalletDialog",
      parent: "/MyWallet",
      icon: faWallet,
    },
    {
      idx: 7,
      name: string.walletReceiveMenu,
      url: "/walletReceive",
      parent: "/MyWallet",
      icon: faWallet,
    },
    {
      idx: 8,
      name: string.walletSendMenu,
      url: "/walletSend",
      parent: "/MyWallet",
      icon: faWallet,
    },
    {
      idx: 9,
      name: string.inquiry,
      url: "/inquiry",
      parent: "/Mypage",
      icon: faQuestionCircle,
    },
    {
      idx: 10,
      name: string.myPage,
      url: "/Mypage",
      icon: faUser,
    },
  ];

  const menuShow = menus.filter((x) => x.idx <= 2 || x.idx > 9);
  const currentPage = menus.find((x) => x.url === location.pathname);

  return (
    <div id="header">
      <div className="navi">
        {menuShow.map((row) => {
          return (
            <div
              key={row.idx}
              onClick={() => navigate(row.url)}
              className={
                "top-menu " +
                (row.idx === currentPage.idx || row.url === currentPage.parent
                  ? "active"
                  : "")
              }
            >
              <FontAwesomeIcon
                icon={row.icon}
                className="menu-icon"
                style={{ fontSize: "20px" }}
              />
              {/* <div className="top-text">{row.name}</div> */}
              <div
                className={
                  row.idx === currentPage.idx || row.url === currentPage.parent
                    ? "menudot active"
                    : "menudot"
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Header;
