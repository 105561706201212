
import util from 'util';
const formatBalance = (value, decimal, place) => {
    if (decimal > 9) decimal = 9;
    value = (value + "").replace(/,/g,'');
    // let valuePre = value.length > decimal ?  value.substr(0, value.length - decimal).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
    let valuePre = value.length > decimal ?  value.substr(0, value.length - decimal) : 0;
    let valuePost = "";
    if (value.length < decimal) {
        for (let i=0;i<decimal-value.length;i++) valuePost += "0";
        valuePost += value;
    }
    else {
        valuePost = value.substr(value.length - decimal, value.length);
    }
    if (valuePost.length > place) valuePost = valuePost.substr(0,place);

    return parseFloat(valuePre + (valuePost == "" ? "" : "." + valuePost));
};
const multiplyBalance = (value, decimal) => {
    if (decimal > 9) decimal = 9;
    for (let i=0;i<decimal;i++) value *= 10;

    return Math.floor(value);
};
const stringFormat = (value, params) => {
    if (params != null) params.forEach(param => {
      value = util.format(value, param);
    });
    return value;
};
const getTxUrl = (coinType, txid) => {
    let url = '';
    if (coinType == 1) url = '';
    else if (coinType == 2) url = 'https://etherscan.io/tx/'+txid
    else if (coinType == 3) url = '';
    else if (coinType == 4) url = 'https://bscscan.com/tx/'+txid
    else if (coinType == 5) url = '';
    else if (coinType == 6) url = 'https://polygonscan.com/tx/'+txid
    else if (parseInt(coinType / 100) == 1 || parseInt(coinType / 100) == 2) url = 'https://etherscan.io/tx/'+txid
    else if (parseInt(coinType / 100) == 4) url = 'https://bscscan.com/tx/'+txid
    else if (parseInt(coinType / 100) == 6) url = 'https://polygonscan.com/tx/'+txid

    
    return url;
};
export {
    formatBalance,
    stringFormat,
    multiplyBalance,
    getTxUrl
};