import { Form, Switch, Button, Divider, Input, Modal, Image } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  httpGet,
  httpUrl,
  httpPost,
  httpPut,
  makeUrl,
  imageUrl,
} from "../api/httpClient";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, withRouter } from "react-router-dom";
import Header from "../components/Header";
import {
  ExclamationCircleOutlined,
  MailOutlined,
  CopyOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import SHA256 from "crypto-js/sha256";
import { enc } from "crypto-js";
import QRCode from "react-qr-code";
import axios from "axios";
import imageCompression from "browser-image-compression";
import { setLogin } from "../actions";
import Const from "../const";
import checkImg from "../img/checkIdentity.png";

import Top from "./Top";

const Mypage = () => {
  const userinfo = useSelector((state) => state.loginReducer.userinfo);
  const string = useSelector((state) => state.stringReducer.string);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [onOtp, setOnOtp] = useState(
    userinfo.securityLevel >= 2 ? true : false
  );
  const [onOtpVisible, setOnOtpVisible] = useState(false);
  const [showSecuritySettings, setShowSecuritySettings] = useState(true);
  const [changeNameVisible, setChangeNameVisible] = useState(false);
  const [changePwVisible, setChangePwVisible] = useState(false);
  const [changeSecPwVisible, setChangeSecPwVisible] = useState(false);
  const [modifyOtpVisible, setModifyOtpVisible] = useState(false);
  const [certiVerified, setCertiVerified] = useState(false);
  const [kycVisible, setKycVisible] = useState(false);
  const [timer, setTimer] = useState(300);
  const [onTimer, setOnTimer] = useState(false);
  const [newName, setNewName] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newSecPassword, setNewSecPassword] = useState("");
  const [email, setEmail] = useState(
    userinfo.id != undefined ? userinfo.id : ""
  );
  const [otpKeyUrl, setOtpKeyUrl] = useState("");
  const [otpKey, setOtpKey] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [msg, setMsg] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [certiCode, setCertiCode] = useState("");
  const [kycStatus, setKycStatus] = useState(-1);
  const [photoIdx, setPhotoIdx] = useState(0);
  const certiCodeToken = useRef("");

  useEffect(() => {
    reloadKyc();
  }, []);

  const onClickOtp = () => {
    console.log("userInfo");
    console.log(userinfo);
    if (userinfo.securityLevel >= 2) {
      Modal.error({ content: string.alreadyOtp });
      return;
    } else {
      setOnOtp(true);
      setOnOtpVisible(!onOtpVisible);
      getOtpKey();
    }
  };

  const onChangePassword = () => {
    // console.log('password='+password)
    // alert(password + userinfo.email);
    let passwordOrg = password + userinfo.email;
    let passwordHash = SHA256(passwordOrg).toString(enc.Hex);
    let newPasswordOrg = newPassword + userinfo.email;
    let newPasswordHash = SHA256(newPasswordOrg).toString(enc.Hex);
    // let newPasswordHash = crypto.createHash('sha256').update(newPasswordOrg, 'utf8').digest().toString('hex');

    httpPost(httpUrl.changePassword2, [], {
      password: passwordHash,
      newPassword: newPasswordHash,
    })
      .then((result) => {
        result = result.data;
        if (result.result) {
          setChangePwVisible(false);
          setPassword("");
          setNewPassword("");
          Modal.success({
            content: string.setSucceed,
          });
        } else {
          if (result.reason == "SAME_PASSWORD") {
            Modal.error({ content: string.changePasswordFailedSamePassword });
          } else if (result.reason == "INVALID_PASSWORD") {
            Modal.error({
              content: string.changePasswordFailedInvalidPassword,
            });
          } else {
            Modal.error({ content: string.changePasswordFailed });
          }
        }
      })
      .catch((e) => {
        console.log("## changePassword error: " + e);
      });
  };

  const onChangeSecPassword = () => {
    let passwordOrg = password + userinfo.email;
    let passwordHash = SHA256(passwordOrg).toString(enc.Hex);
    // let passwordHash = crypto.createHash('sha256').update(passwordOrg, 'utf8').digest().toString('hex');
    let newSecPasswordOrg = newSecPassword + userinfo.email;
    let newSecPasswordHash = SHA256(newSecPasswordOrg).toString(enc.Hex);
    // let newSecPasswordHash = crypto.createHash('sha256').update(newSecPasswordOrg, 'utf8').digest().toString('hex');

    httpPost(httpUrl.changeSecPassword, [], {
      password: passwordHash,
      newPassword: newSecPasswordHash,
    })
      .then((result) => {
        result = result.data;
        if (result.result) {
          setChangeSecPwVisible(false);
          setPassword("");
          setNewPassword("");
          Modal.success({
            content: string.setSucceed,
          });
        } else {
          if (result.reason == "SAME_PASSWORD") {
            Modal.error({ content: string.changePasswordFailedSamePassword });
          } else if (result.reason == "INVALID_PASSWORD") {
            Modal.error({
              content: string.changePasswordFailedInvalidPassword,
            });
          } else {
            Modal.error({ content: string.changePasswordFailed });
          }
        }
      })
      .catch((e) => {
        console.log("## changeSecPassword error: " + e);
      });
  };
  // otp키 가져오기
  const getOtpKey = () => {
    httpGet(httpUrl.walletOtpGetKey, [])
      .then((result) => {
        result = result.data;
        console.log("## walletOtpGetKey: " + JSON.stringify(result));
        if (result.result) {
          setOtpKey(result.key);
          setOtpKeyUrl(result.url);
        }
      })
      .catch((e) => {
        console.log("## walletOtpGetKey error: " + e);
      });
  };
  // otp설정
  const verifyOtp = () => {
    httpGet(httpUrl.walletOtpVerify, [authCode, verifyCode])
      .then((result) => {
        result = result.data;
        console.log("## walletOtpVerifyOtp: " + JSON.stringify(result));
        if (result) {
          setOnOtp(true);
          setOnOtpVisible(false);
          setCertiVerified(false);
          const modifyUserInfo = userinfo;
          modifyUserInfo.securityLevel = 2;
          dispatch(setLogin(modifyUserInfo));
          reactLocalStorage.setObject(Const.appCode + "#userinfo", {
            ...userinfo,
            securityLevel: 2,
          });
          window.location.reload();
          alert(string.myinfoOtpComplete);
        } else {
          alert(string.myinfoOtpError);
        }
      })
      .catch((e) => {
        console.log("## walletOtpGetKey error: " + e);
      });
  };

  const onCopyCode = () => {
    window.navigator.clipboard.writeText(otpKey);
    Modal.success({ content: string.otpCopied });
  };

  // 인증번호전송
  // onPressSendCertiCode = () => {
  //     if (this.state.certiVerified) return;
  //     if (this.state.onTimer) return;
  //     if (this.state.phone == undefined || this.state.phone == '' || this.state.phone.length < 9) {
  //         alert(this.props.string.signUpRuleMsg08);
  //         return;
  //     }
  //     let fullPhone = this.state.phone.replace(/^-/, "");
  //     httpPost(httpUrl.userPhoneauthGenerate, [], {phone: fullPhone}).then((result)=>{
  //       console.log("## userPhoneauthGenerate result: " + JSON.stringify(result));
  //       result = result.data.token;
  //       this.certiCodeToken = result;
  //       if(result && result != ''){
  //         this.setState({ onTimer: true, phoneEditable: false });
  //         this.interval = setInterval(
  //           () => {
  //             if(this.state.timer == 0){
  //               clearInterval(this.interval);
  //               this.setState({ onTimer: false, timer: 180});
  //               return;
  //             }
  //             this.setState({ timer: this.state.timer - 1 });
  //           },
  //           1000
  //         );
  //       } else {
  //         alert(this.props.string.certiCodeSendFail);
  //         console.log("## userPhoneauthGenerate server error");
  //       }
  //     }).catch(e=>{
  //       console.log ("## userPhoneauthGenerate error: " + e)
  //     });
  //   }

  const onPressSendCertiCode = () => {
    if (certiVerified) return;
    if (onTimer) return;
    httpPost(httpUrl.emailAuthGenerate, [], {})
      .then((result) => {
        console.log(
          "## userPhoneauthGenerate result: " + JSON.stringify(result)
        );
        result = result.data.token;
        certiCodeToken.current = result;
        if (result && result != "") {
          setOnTimer(true);
          alert(string.myInfoOtpMail);
          let interval = setInterval(() => {
            if (timer == 0) {
              clearInterval(interval);
              setOnTimer(false);
              setTimer(300);
              return;
            }
            setTimer(timer - 1);
          }, 1000);
        } else {
          alert(string.certiCodeSendFail);
          console.log("## userPhoneauthGenerate server error");
        }
      })
      .catch((e) => {
        console.log("## userPhoneauthGenerate error: " + e);
      });
  };

  //  인증번호 확인
  const onPressCheckCertiCode = () => {
    if (certiVerified) return;
    if (certiCode == undefined || certiCode == "") {
      alert(string.certiCodeFail);
      return;
    }
    httpPost(httpUrl.otpRemove, [certiCode])
      .then((result) => {
        console.log("## userPhoneauthCheck result: " + JSON.stringify(result));
        result = result.data;
        if (result) {
          setCertiVerified(true);
          setModifyOtpVisible(false);
          setOnOtp(false);
          const modifyUserInfo = userinfo;
          modifyUserInfo.securityLevel = 1;
          dispatch(setLogin(modifyUserInfo));
          reactLocalStorage.setObject(Const.appCode + "#userinfo", {
            ...userinfo,
            securityLevel: 1,
          });
          window.location.reload();
          alert(string.changeOtpComplete);
        } else {
          console.log("## userPhoneauthCheck server error");
          setCertiCode("");
          alert(string.certiCodeWrong);
          if (result == "EXPIRED") {
            alert(string.certiCodeExpired);
          }
        }
      })
      .catch((e) => {
        console.log("## userPhoneauthCheck error: " + e);
      });
  };
  //   이름변경
  const onChangeName = () => {
    const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z]+$/;
    if (!regex.test(newName)) {
      alert(string.enterNicknameValidation);
      setNewName("");
      return;
    }
    httpPut(httpUrl.userUpdateNickname, [newName])
      .then((result) => {
        result = result.data;
        if (result) {
          alert(string.setSucceed);
        } else {
          alert(string.setFailed);
        }
      })
      .catch((e) => {
        console.log("## userUpdateNickname error: " + e);
      });
  };

  // 파일등록
  const regist = () => {
    if (photoIdx === 0 || photoIdx == null) {
      alert("사진을 등록해주세요.");
      return;
    }
    httpPost(httpUrl.kycRegist, [photoIdx], {})
      .then((result) => {
        console.log(JSON.stringify(result));
        result = result.data;
        if (result) {
          console.log("## sent success");
          alert(string.fileUploadComplete);
          setKycVisible(false);
          reloadKyc();
        } else {
          alert(string.fileUploadError);
        }
      })
      .catch((e) => {
        console.log(e);
        alert(string.fileUploadError);
      });
  };

  const reloadKyc = () => {
    httpGet(httpUrl.kycStatus, [], {})
      .then((result) => {
        //   console.log('kycStatus=' + JSON.stringify(result));
        setKycStatus(result.data);
      })
      .catch(() => {});
  };

  // 파일업로드
  const actionImgCompress = async (file) => {
    if (!file) return;
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      console.log(compressedFile);

      const reader = new FileReader();
      reader.readAsDataURL(compressedFile);
      reader.onloadend = () => {
        const base64data = reader.result;
        handleFileUpload(base64data);
      };
    } catch (e) {
      console.log(e);
    }
  };

  const handleFileUpload = async (data) => {
    const byteString = atob(data.split(",")[1]);

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ia], {
      type: "image/jpeg",
    });
    const file = new File([blob], "image.jpg");

    var formData = new FormData();
    formData.append("file", file);

    await axios
      .post(makeUrl(httpUrl.FileUpload, []), formData, {
        withCredentials: true,
      })
      .then((res) => {
        if (
          res.status === 200 &&
          res.data.result === "SUCCESS" &&
          res.data.data.result
        ) {
          setPhotoIdx(res.data.data.idx);
        } else {
          alert(string.fileUploadError);
        }
      })
      .catch((e) => {
        alert(string.fileUploadError);
      });
  };

  return (
    <div id="myinfo">
      <Top />
      <Header />
      {/* 내정보 변경 */}
      <div className="container">
        <div className="title">{string.myPage}</div>
        <div>
          <button
            onClick={() => {
              setChangeNameVisible(!changeNameVisible);
            }}
            className={changeNameVisible ? "myinfoBtn close" : "myinfoBtn"}
          >
            {changeNameVisible ? string.close : string.change}
          </button>
          {changeNameVisible && (
            <button
              onClick={() => {
                onChangeName();
              }}
              className="myinfoBtn saveBtn"
            >
              {string.saveData}
            </button>
          )}
        </div>
      </div>
      <div className="container">
        <div className="objTitle">{string.idEmail}</div>
        <div className="objValue">{userinfo.id}</div>
      </div>
      <div className="container">
        <div className="objTitle">{string.name}</div>
        {changeNameVisible ? (
          <div className="formBox">
            <input
              autoComplete="false"
              defaultValue={userinfo.name}
              onChange={(e) => {
                setNewName(e.target.value);
              }}
            />
          </div>
        ) : (
          <div className="objValue">{userinfo.name}</div>
        )}
      </div>

      {/* <div className='container'>
            <div className='objTitle'>{string.phone}</div>
            <div className='objValue'>{userinfo.phone}</div>
        </div> */}

      <Divider style={{ backgroundColor: "#fff3" }} />

      {/* 비밀번호 변경 */}

      <div className="container">
        <div className="title">{string.password}</div>
        <div>
          <button
            onClick={() => {
              setChangePwVisible(!changePwVisible);
            }}
            className={changePwVisible ? "myinfoBtn close" : "myinfoBtn"}
          >
            {changePwVisible ? string.close : string.change}
          </button>
          {changePwVisible && (
            <button
              onClick={() => {
                onChangePassword();
              }}
              className="myinfoBtn saveBtn"
            >
              {string.saveData}
            </button>
          )}
        </div>
      </div>
      {changePwVisible && (
        <>
          <div className="container">
            <div className="objTitle">{string.nowPw}</div>
            <div className="formBox">
              <input
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type="password"
                placeholder={string.inputCurrentPassword}
              />
            </div>
          </div>
          <div className="container">
            <div className="objTitle">{string.newPw}</div>
            <div className="formBox">
              <input
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                type="password"
                placeholder={string.inputNewPassword}
              />
            </div>
          </div>
        </>
      )}

      <Divider style={{ backgroundColor: "#fff3" }} />

      {/* 보안 비밀번호 변경 */}
      <div className="container">
        <div className="title">{string.securityPw}</div>
        <div>
          <button
            onClick={() => {
              setChangeSecPwVisible(!changeSecPwVisible);
            }}
            className={changeSecPwVisible ? "myinfoBtn close" : "myinfoBtn"}
          >
            {changeSecPwVisible ? string.close : string.change}
          </button>
          {changeSecPwVisible && (
            <button
              onClick={() => {
                onChangeSecPassword();
              }}
              className="myinfoBtn saveBtn"
            >
              {string.saveData}
            </button>
          )}
        </div>
      </div>
      {changeSecPwVisible && (
        <>
          <div className="container">
            <div className="objTitle">{string.nowPw}</div>
            <div className="formBox">
              <Input
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type="password"
                placeholder={string.inputCurrentSecPassword}
              />
            </div>
          </div>
          <div className="container">
            <div className="objTitle">{string.newPw}</div>
            <div className="formBox">
              <Input
                onChange={(e) => {
                  setNewSecPassword(e.target.value);
                }}
                type="password"
                placeholder={string.inputNewSecPassword}
              />
            </div>
          </div>
        </>
      )}
      <Divider style={{ backgroundColor: "#fff3" }} />

      {/* OTP 설정 */}
      {/* <div className="container">
            <div className="title">{string.setupOtp} 
                <span style={{cursor:'pointer'}} 
                    onClick={()=> {Modal.info({content: string.myinfoTwoSecurityInfo})}}> <ExclamationCircleOutlined /></span>
            </div>                            
            <Switch 
                defaultChecked={onOtp} 
                checked={onOtp} 
                onChange={()=>{ onClickOtp() }} 
                disabled={onOtp}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                />
        </div> */}
      {/* {onOtpVisible && (
            <>
                <div className='container2'>
                    <div className='box'> 
                        <div className='title'>
                            <MailOutlined style={{marginRight: 6}}/>
                            {string.myInfoOtpMail}
                        </div>      
                        <div className='formBox'>     
                            <input onChange={(e)=>{setVerifyCode(e.target.value)}} />        
                        </div>                   
                    </div>               
                    <div className='box2'> 
                        <div className=''>
                            <QRCode                                    
                                size={160}
                                color='#000'
                                value={otpKeyUrl}                                 
                            />
                            <p className='otykey'>{otpKey}<span><CopyOutlined onClick={()=>{onCopyCode();}}/></span></p>
                        </div>
                        <div className='formBox'>     
                            <input onChange={(e)=>{setAuthCode(e.target.value)}} />        
                        </div>                  
                    </div>
                </div>
                <div className='btnBox'>                             
                    <button className='loginBtn' type="primary" htmlType="submit" onClick={verifyOtp}>{string.submit}</button> 
                </div>
            </>
        )} */}

      {/* <Divider style={{backgroundColor:'#fff3'}}/>    */}

      {/* OTP 변경 */}
      {/* <div className="container">
            <div className='title'>{string.changeOtp}</div>                               
            <div>
                <button className='myinfoBtn'
                    onClick={()=>{
                        if (userinfo.securityLevel > 1) setModifyOtpVisible(!modifyOtpVisible)
                        else alert(string.kycOTPerror);
                    }}>
                    {modifyOtpVisible ? string.close : string.change}
                </button>                                                                                       
            </div> 
        </div>          */}
      {/* {modifyOtpVisible && (
            <>
                <div className='container'>
                    <div>{string.idEmail}</div>            
                    <div className='formBox'>  
                        <p className='noModifyId'>{userinfo.email}</p>                    
                    </div>  
                </div>
                <div className='btnBox' style={{marginTop: -10, marginBottom: 16}}>
                    <button onClick={()=>onPressSendCertiCode()} className="myinfoBtn otp">
                        {certiVerified ? string.completed : (onTimer ? timer : string.sendCertiCode2)}
                    </button>  
                </div>
                <div className='container'>
                    <div>{string.certiCode}</div>                           
                    <div className='formBox'>           
                        <Input 
                            onChange={(e)=>{
                                let text = e.target.value.replace(/[^0-9]+/g,'');
                                setCertiCode(text)
                            }}                                            
                            placeholder={string.inputCertiCode} />                                        
                    </div>                                           
                </div>
                <div className='btnBox' style={{marginTop: -10, marginBottom: 16}}>   
                    <button onClick={()=>onPressCheckCertiCode()} 
                        className={ certiVerified ? "myinfoBtn otp active" : "myinfoBtn otp"} disabled={certiVerified}>
                        {certiVerified ? string.completed : string.checkCertiCode}
                    </button>        
                </div>    
            </>
        )}           */}

      {/* <Divider style={{backgroundColor:'#fff3'}}/>    */}

      {/* KYC 인증 */}
      {/* <div className="container">
            <div className="title">{string.certiKYC}</div>  
            <div className='kycStatus'>
                {(userinfo.securityLevel == 3 || kycStatus == 1 ) ? (
                <p className='completed'>{string.certiCompleted}</p>
                ) : (kycStatus == 0 ? (
                <p>{string.auditing}</p>
                ) : (
                <button        
                    className="myinfoBtn"                               
                    onClick={() => {
                    if (userinfo.securityLevel != 2 ) {
                        Modal.error({content: string.kycOTPerror})
                    }
                    else setKycVisible(!kycVisible)                                        
                }}>
                    신청하기
                </button>
                ))}
            </div>                                              
        </div> */}
      {/* { kycVisible && (
            <div className="container3">
                <img src={checkImg} className='checkIdentity'/>
                <div className='info'>{string.kycInfo}</div>           
                <div className='formBox'>                  
                    <input                                                                     
                        type="file"
                        accept="image/*;capture=camera"          
                        onChange={e => {
                            actionImgCompress(e.target.files[0]);                                                
                        }}                           
                        className="file"/>                                                                                                                                         
                </div>
                <div className='btnBox'>                             
                    <button className='loginBtn' type="primary" htmlType="submit" onClick={regist}>{string.submit}</button> 
                </div>
            </div>                    
        )}       */}
      {/* <Divider style={{backgroundColor:'#fff3'}}/>    */}

      {/* KYC 인증 */}
      <div className="container">
        <div className="title">{string.inquiry}</div>
        <button className="myinfoBtn" onClick={() => navigate("/inquiry")}>
          {string.inquire}
        </button>
      </div>
    </div>
  );
};

export default Mypage;
