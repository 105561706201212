import { Input, Button, Modal, Popover } from "antd";
import React, { useRef, useState } from "react";
import { httpUrl, httpPost } from "../api/httpClient";
import { stringReducer } from "../actions";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MailOutlined, SmileOutlined } from "@ant-design/icons";
import global from "../img/common/global.png";
import logo from "../img/text-logo2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faLock,
  faGlobe,
  faFaceSmile,
} from "@fortawesome/free-solid-svg-icons";

const ModifyPassword = () => {
  const formRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.stringReducer.language);
  const string = useSelector((state) => state.stringReducer.string);
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(0);
  const [globalOpen, setGlobalOpen] = useState(false);
  const handleSubmit = async () => {
    console.log();
    const res = await httpPost(httpUrl.userFindPassword, [email, language], {});
    if (!res.data) {
      Modal.error({
        title: string.findId,
        content: string.mnemonicError,
      });
    } else {
      setStep(1);
    }
  };
  const changeLan = (lan) => {
    dispatch(stringReducer(lan));
  };
  return (
    <div id="modifyPw">
      <div className="header">
        <Popover
          content={
            <div className="pophover">
              <div
                onClick={() => changeLan("ko")}
                className={language == "ko" ? "lan active" : "lan"}
              >
                한국어
              </div>
              <div
                onClick={() => changeLan("en")}
                className={language == "en" ? "lan active" : "lan"}
              >
                English
              </div>
              <div
                onClick={() => changeLan("ja")}
                className={language == "ja" ? "lan active" : "lan"}
              >
                日本語
              </div>
            </div>
          }
          trigger={"click"}
          open={globalOpen}
          onOpenChange={() => setGlobalOpen(!globalOpen)}
        >
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="hoverEffect"
          >
            <FontAwesomeIcon icon={faGlobe} className="globalImg" />
            <p>Select language</p>
          </div>
        </Popover>
      </div>
      <div className="container">
        <img src={logo} className="logoImg" />
        {step === 0 ? (
          <>
            <div
              className="info"
              style={{
                fontSize: 16,
                color: "grey",
                textAlign: "center",
                marginBottom: 35,
                fontWeight: "600",
              }}
            >
              Enter your registered email and a link to <br /> change your
              password will be emailed to you.
            </div>

            <div className="formBox">
              <input
                className="login-input"
                autoComplete="false"
                placeholder={string.idEmail}
                style={{ textAlign: "left" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="btnBox">
              <button
                className="loginBtn"
                type="primary"
                htmlType="submit"
                onClick={handleSubmit}
              >
                {string.ok}
              </button>
            </div>
            <div className="cancel" onClick={() => navigate(-1)}>
              {string.cancel}
            </div>
          </>
        ) : (
          <div className="findPwResult">
            <FontAwesomeIcon
              icon={faFaceSmile}
              size="3x"
              beat
              fade
              className="icon"
            />
            <p>{string.findPasswordEmailSentSuccess}</p>
            <div className="btnBox">
              <button
                className="loginBtn"
                type="primary"
                htmlType="submit"
                onClick={() => navigate("/")}
              >
                {string.login}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ModifyPassword;
