
import { Form, Input, Checkbox, Button, Modal, Table, Divider, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import SHA256 from 'crypto-js/sha256';
import crypto, { enc, timingSafeEqual } from 'crypto-js';
import { useDispatch, useSelector } from "react-redux";
import { stringReducer, setLogin, setLogout, setCoinCurrency, setCoinList, setCoinBalance } from "../actions";
import { Link, useNavigate, withRouter } from "react-router-dom";
import { formatBalance, multiplyBalance, stringFormat } from "../lib/util/coinUtil";
import string from '../language/StringKo';
import moment from "moment";
import { comma } from '../lib/util/numberUtil';
import Header from '../components/Header';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import down from "../img/common/arrow-down.png";
import up from "../img/common/arrow-up.png";
import con from '../const';

import Top from './Top';
import { reactLocalStorage } from 'reactjs-localstorage';
import CoinSelectBox from '../components/common/CoinSelectBox';
import { ArrowDownOutlined, } from '@ant-design/icons';

const FormItem = Form.Item;

const Exchange = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userinfo = reactLocalStorage.getObject(con.appCode + "#userinfo"); 
  const coinList = useSelector(state => state.coinListReducer);
  const language = useSelector(state => state.stringReducer.language);
  const string = useSelector(state => state.stringReducer.string);
  const [toCoin, setToCoin] = useState(601)
  const [fromCoin, setFromCoin] = useState(2)
  const [ratio, setRatio] = useState(1.0)
  const [tradeFee, setTradeFee] = useState(1.0)
  const [fromCoinAmount, setFromCoinAmount] = useState('')
  const [toCoinAmount, setToCoinAmount] = useState('')
  const [securityPw, setSecurityPw] = useState('')
  const [otp, setOtp] = useState('')
  const [balanceLoaded, setBalanceLoaded] = useState(true)
  const [feeLoaded, setFeeLoaded] = useState(true)

  useEffect(()=>{
    ratioSetting()
    requestBalance(fromCoin);
    requestBalance(toCoin);
    requestFee(fromCoin);
    setFromCoinAmount(0);
    setToCoinAmount(0);
  },[fromCoin, toCoin])

  const ratioSetting = () => {
    
    let fromPrice = coinList.find(x=>x.coinType == fromCoin).price;
    let toPrice = coinList.find(x=>x.coinType == toCoin).price;

    setRatio(fromPrice / toPrice)
  }

  const onFilp = () =>{
    let prevFromCoin = fromCoin
    let prevToCoin = toCoin
    setFromCoin(prevToCoin)
    setToCoin(prevFromCoin)
    setFromCoinAmount('')
    setToCoinAmount('')
    setRatio(1.0)
  }

  const requestFee = async (coinType) => {
    const result = await httpGet(httpUrl.coinFee, [coinType], {})
    setTradeFee(result.data[0].tradeFee/100);
  }

  const requestBalance = async (coinType) => {
    const result = await httpGet(httpUrl.balance, [coinType], {});
    dispatch(setCoinBalance(result.data));
  };

  const doExchange = () => {

    if (!fromCoinAmount || fromCoinAmount == '') {
      alert(string.exchangeCoinInputAmount)
      return;
    }
    
    if (securityPw.length < 4) {
      alert(string.sendCoinFailedNoSecPassword)
      return;
    }

    if (userinfo.securityLevel > 1 && otp.length < 6) {
      alert(string.inputOtpValidation)
      return;
    }

    // const realSpend = fromCoinAmount * (1+coinList.find(x => x.coinType == fromCoin).tradeFee*100000); //안씀 총액에서 수수료 제외
    if (fromCoinAmount <= coinList.find(x=>x.coinType == fromCoin).balance) {

      const fromString = fromCoinAmount + ' ' + coinList.find(x=>x.coinType == fromCoin).symbol
      const toString = coinList.find(x=>x.coinType == toCoin).name

      const secPasswordHash = SHA256(securityPw.toString() + userinfo.email).toString(enc.Hex);

      Modal.confirm({
        title: string.exchange,
        content: stringFormat(string.exchangeConfirmMessage,[fromString, toString]),
        onOk: ()=>{
          httpPost(httpUrl.trade, [], { 
            fromCoinType: fromCoin,
            toCoinType: toCoin,
            securityPassword: secPasswordHash,
            otpCode: otp,
            amount: multiplyBalance(fromCoinAmount, coinList.find(x=>x.coinType == fromCoin).decimal)
          }).then((result)=>{
            console.log('logout result='+result);
            //실패알림, 잔액갱신, 수수료갱신, 필드클리어

            if (result.data == "SUCCESS") {
              setFromCoinAmount('')
              setToCoinAmount('')
              setSecurityPw('')
              setOtp('')
              requestBalance(0)
              alert(string.exchangeCoinSuccess);
            }
            else if (result.data == "INSUFFICIENT_BALANCE") alert(string.sendCoinFailedInsufficientBalance);
            else if (result.data == "LOCKED") alert(string.sendCoinFailedLocked);
            else if (result.data == "INVALID_OTP") alert(string.sendCoinFailedInvalidSecPassword);
            else if (result.data == "INVALID_SECURITY_PASSWORD") alert(string.sendCoinFailedInvalidSecPassword);
            else if (result.data == "LIMIT_EXCEED") alert(string.sendCoinFailedLimitExceed);
            else if (result.data == "REQUIRED_OTP_DAILY") alert(string.sendCoinFailedOtpRequired);
            else if (result.data == "REQUIRED_OTP") alert(string.sendCoinFailedOtpRequired);
            else if (result.data == "INVALID_COINTYPE") alert(string.InvalidCoinType);
            else if (result.data == "ORGANIZATION_CANNOT") alert(string.InvalidFranchise);
            
          }).catch(e=>console.log("## logout error: " + e));
        }
      });
    }
    else {
      alert('잔액이 부족합니다.')

    }
  }

  const onChangeFromAmount= (e) =>{
    console.log(e)
    const fromVal = e.target.value;
    let toVal = '';
    if (!isNaN(fromVal)) {
      toVal = fromVal * (1-tradeFee/100) * ratio;
    }
    setFromCoinAmount(e.target.value)
    setToCoinAmount(toVal.toFixed(6))
  }

  return (
    <div id="exchange">
      <Top />
      <Header />
      <div className='exchangeCoinBox'>
        <div className='line'>
          <div className='title'>{string.exchangeUseCoin}</div>
          <div className='content'>({string.myValue}: <span className='contentBalance'>{comma(coinList.find(x=>x.coinType == fromCoin).balance)}</span> {coinList.find(x=>x.coinType == fromCoin).symbol})</div>
        </div>
        <div className='line'>
          <img src={coinList.find(x => x.coinType == fromCoin).image} className='coinImg' />
          <CoinSelectBox
                className="coinSelBox"
                placeholder={string.viewAll}
                onChange={(value) => {
                  setFromCoin(value)
                }}
                value={fromCoin}
                coinType={[2,201]}
                coinList={coinList}
          />
          <div className='exchangeInputBorder'>
            <input
              className='exchangeInput'
              placeholder={string.exchangeCoinInputAmount}
              onChange={(e)=>onChangeFromAmount(e)}
              value={fromCoinAmount}
            />
          </div>
        </div>
      </div>
      <div
        className='flipBtn'
        // onClick={onFilp}
      >
<ArrowDownOutlined />
        {/* <img src={require('../img/common/flip.png')} style={{transform: 'rotate(90deg)'}} /> */}
      </div>
      <div className='exchangeCoinBox'>
        <div className='line'>
          <div className='title'>{string.exchangeReceiveCoin}</div>
          <div className='content'>({string.myValue}: <span className='contentBalance'>{comma(coinList.find(x=>x.coinType == toCoin).balance)}</span> {coinList.find(x=>x.coinType == toCoin).symbol})</div>
        </div>
        <div className='line'>
          <img src={coinList.find(x => x.coinType == toCoin).image} className='coinImg' />
          <div>{coinList.find(x => x.coinType == toCoin).name}</div>
          <div className='exchangeInputBorder'>
            <input
              className='exchangeInput'
              placeholder={string.exchangeCoinInputAmount}
              value={toCoinAmount}
              disabled
            />
          </div>
        </div>
      </div>

      <div className='priceLine' style={{marginTop: '30px', paddingLeft: '10px'}}>
        <div>{string.exchangeRate}</div>
        <div>
          <div>
            1.0 {coinList.find(x => x.coinType == fromCoin).symbol} 
            ≈
            {((1-tradeFee/100)*ratio).toFixed(6)} {coinList.find(x => x.coinType == toCoin).symbol}
          </div>
          <div style={{fontSize: 12}}>{string.RateInform}</div>
        </div>
      </div>
      <div className='priceLine' style={{marginTop: '10px', paddingLeft: '10px'}}>
        <div>{string.fee}</div>
        <div style={{color: "#000"}}>{tradeFee}%</div>
      </div>
      
      <div className='exchangeInputBorder' style={{marginTop: '30px'}}>
        <input 
          className='exchangeInput'
          placeholder={string.shortTermSecPassword}
          // value={secPassword}
          onChange={(e)=>setSecurityPw(e.target.value)}
          type='password'
          value={securityPw}
        />
      </div>
      {userinfo.securityLevel >= 2 && (
        <div className='exchangeInputBorder' style={{marginTop: '10px'}}>
          <input
            className='exchangeInput'
            placeholder="OTP"
            onChange={(e)=>setOtp(e.target.value)}
            value={otp}
          />
        </div>
      )}

      <div className='exchangeButtonWrapper'>
        <button onClick={()=>{doExchange()}} className='saveBtn'>
          {string.saveData}
        </button>
      </div>
    </div>
  )
}


export default Exchange;